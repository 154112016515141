import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createUser,
  logOutUser,
  setMonthYear,
  loadStatus,
} from "../actions/postAction";
import ReactToPrint from 'react-to-print';

import axios from "axios";
import { BASE_URL } from "../constant";
import Swal from "sweetalert2";
import { NetworkError } from "./NetworkError";
import { Loading } from "./Loading";

import moment from "moment";
import "moment-timezone";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme, Table } from "@material-ui/core";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { endpoints, http } from "../configs/https";
import { customTheme, YEARS } from "./UserHome";
import "./style.css";
import { Link } from "react-router-dom";
import ViewReport from "./ViewReport";


const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

class ReportDashboard extends Component {
  constructor(props) {
    super(props);
    var d = new Date();
    var n = d.getFullYear();
    var m = d.getMonth() + 1;
    this.state = {
      data: null,
      month: m !== 1 ? m - 1 : m,
      showFilter: true,
      year: n,
      level: 1,
      networkError: false,
      isLoading: false,
      modal: false,
      modalData: null,
      modalType: "",
      modalOffice: "",
      id: 1,
      viewReport: false,
      name: ""
    };
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  setModalData(office = "tabligh", type = "submitted") {
    this.setState({
      modalData: this.state.data[office][type],
      modalType: type,
      modalOffice: office,
      modal: true,
    });
  }

  showReport(postId, name) {
    console.log("post id")
    console.log(postId)
    this.setState({
      id: postId,
      name,

    }
      , () => {
        this.setState({
          viewReport: true,
        })
      })
  }

  handleChangeEvent(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  fetchQuery() {
    this.setState({
      isLoading: true,
      showFilter: false,
      data: null,
      networkError: false,
    });
    const { month, year, level } = this.state;

    let queryString = "";

    if (month !== 0) {
      queryString += "month=" + month;
    }

    console.log(year);
    if (year !== 0) {
      queryString += "&year=" + year;
    }

    if (level !== 0) {
      queryString += "&report_level=" + level;
    }

    http
      .get(endpoints.MONTHLY_REPORT + queryString)
      .then((response) => {
        console.log("hello");
        console.log(response.data["atfal"]["not_submitted"]);
        this.setState({
          isLoading: false,
          data: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          networkError: true,
        });
        let errorMessage =
          (typeof error === "string" && error) ||
          "An error occured, please try again in a moment.";

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });

        Toast.fire({
          icon: "error",
          title: errorMessage,
        });
      });
  }

  month_name(dt) {
    var mlist = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return mlist[dt];
  }
  options = [
    { label: 2021, value: 2021 },
    { label: 2020, value: 2020 },
    { label: 2019, value: 2019 },
    { label: 2018, value: 2018 },
    { label: 2017, value: 2017 },
    { label: 2016, value: 2016 },
    { label: 2015, value: 2015 },
    { label: 2014, value: 2014 },
    { label: 2013, value: 2013 },
    { label: 2012, value: 2012 },
    { label: 2011, value: 2011 },
    { label: 2010, value: 2010 },
    { label: 2009, value: 2009 },
    { label: 2008, value: 2008 },
    { label: 2007, value: 2007 },
    { label: 2006, value: 2006 },
    { label: 2005, value: 2005 },
    { label: 2004, value: 2004 },
    { label: 2003, value: 2003 },
    { label: 2002, value: 2002 },
    { label: 2001, value: 2001 },
    { label: 2000, value: 2000 },
    { label: 1775, value: 1775 },
  ];

  months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  componentDidMount() {

    document.title = "Dashboard - MKAN REPORT";
    this.fetchQuery();
  }
  handleChangeYear(value) {
    const { month } = this.props.monthYear;
    this.setState({ year: value });
    const monthYear = {
      month: month,
      year: value.value,
    };
    this.props.setMonthYear(monthYear);
    this.props.loadStatus(month, value.value);
  }

  handleChangeMonth(value) {
    // this.setState({ month: value });

    // this.checkStatus(value.value, this.state.value.value);
    const { year } = this.props.monthYear;
    this.setState({ month: value });
    const monthYear = {
      month: value.value,
      year: year,
    };
    this.props.setMonthYear(monthYear);
    this.props.loadStatus(value.value, year);
  }
  applyFilter() {
    this.fetchQuery();
  }
  getLevel = function (level) {
    if (level === "1") {
      return "Ilaqa";
    } else if (level === "2") {
      return "Dila";
    } else if (level === "3") {
      return "Muqami";
    }
  };

  render() {
    const {
      month,
      year,
      isLoading,
      level,
      data,
      modalData,
      modalType,
      modalOffice,
      viewReport,
      id,
      name,
    } = this.state;

    return (
      <MuiThemeProvider theme={customTheme}>
        <section className="content">

          {this.state.networkError ? (
            <NetworkError />
          ) : (
            <div>
              {!viewReport ?
                <div>
                  <div className="col-md-12">
                    <div>
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                          <FormControl className={"form-control"}>
                            <InputLabel id="demo-simple-select-label">
                              Month
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={month}
                              name={"month"}
                              onChange={this.handleChangeEvent}
                            >
                              <MenuItem value={1}>Jan</MenuItem>
                              <MenuItem value={2}>Feb</MenuItem>
                              <MenuItem value={3}>Mar</MenuItem>
                              <MenuItem value={4}>Apr</MenuItem>
                              <MenuItem value={5}>May</MenuItem>
                              <MenuItem value={6}>Jun</MenuItem>
                              <MenuItem value={7}>Jul</MenuItem>
                              <MenuItem value={8}>Aug</MenuItem>
                              <MenuItem value={9}>Sep</MenuItem>
                              <MenuItem value={10}>Oct</MenuItem>
                              <MenuItem value={11}>Nov</MenuItem>
                              <MenuItem value={12}>Dec</MenuItem>
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 mt-5">

                          <FormControl className={"form-control"}>
                            <InputLabel id="demo-simple-select-label">
                              Year
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={year}
                              name={"year"}
                              onChange={this.handleChangeEvent}
                            >
                              {YEARS().reverse().map((option, index) => (
                                <MenuItem value={option}>{option}</MenuItem>
                              ))}


                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                          <FormControl className={"form-control"}>
                            <InputLabel id="demo-simple-select-label">
                              Level
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={level}
                              name={"level"}
                              onChange={this.handleChangeEvent}
                            >
                              <MenuItem value={3}>Muqami</MenuItem>
                              <MenuItem value={2}>Dila</MenuItem>
                              <MenuItem value={1}>Ilaqa</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <button
                        onClick={() => this.applyFilter()}
                        className="btn btn-success mt-5"
                      >
                        Apply Filter
                      </button>
                    </div>
                  </div>

                  {/* <center>
              <h2>Your report status for {moment().format("MMMM")} </h2>
            </center> */}
                  {isLoading ? (
                    <div className="text-center container">
                      <center>
                        <Loading />
                      </center>
                    </div>
                  ) : (
                    <div>
                      {data && (
                        <div >
                          <ReactToPrint
                            trigger={() => {
                              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                              // to the root node of the returned component as it will be overwritten.
                              return <a href="#" className={"btn btn-danger float-right"}>Print!</a>;
                            }}
                            content={() => this.componentRef}
                          />

                          <div class="clearfix"></div>

                          <div style={{ display: 'none' }}>
                            <ComponentToPrintDasboard data={data} ref={el => (this.componentRef = el)} level={this.getLevel(level.toString())} year={year} month={moment()
                              .month(month - 1)
                              .format("MMMM")} />
                          </div>

                        </div>
                      )}
                      {data && (
                        <div className="row">


                          {data.tabligh && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-warning card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">check_circle</i>
                                  </div>
                                  <p className="card-category">Tabligh</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("tabligh", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.tabligh.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("tabligh", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.tabligh.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.atfal && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-success card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">child_care</i>
                                  </div>
                                  <p className="card-category ">Atfal</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("atfal", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.atfal.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("atfal", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.atfal.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.tajnid && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-rose card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">
                                      people_outlin...
                                    </i>
                                  </div>
                                  <p className="card-category">Tajneed</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("tajnid", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.tajnid.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("tajnid", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.tajnid.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.secretariat && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">home</i>
                                  </div>
                                  <p className="card-category">A'itmad</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("secretariat", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted :{" "}
                                      {data.secretariat.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData(
                                      "secretariat",
                                      "not_submitted"
                                    )
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.secretariat.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.audit && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-warning card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">money</i>
                                  </div>
                                  <p className="card-category">Muhasib</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("audit", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.audit.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("audit", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.audit.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.waqaramal && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-success card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">accessibility</i>
                                  </div>
                                  <p className="card-category">Waqaramal</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("waqaramal", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.waqaramal.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("waqaramal", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.waqaramal.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* Maal */}

                          {data.maal && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-rose card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">
                                      account_balance_wallet
                                    </i>
                                  </div>
                                  <p className="card-category">Maal</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("maal", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.maal.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("maal", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.maal.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.general && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">room</i>
                                  </div>
                                  <p className="card-category">Umoomi</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("general", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.general.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("general", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.general.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.health && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-warning card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">
                                      health_and_safety
                                    </i>
                                  </div>
                                  <p className="card-category">SEH'HAT -E- JISMANI</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("health", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.health.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("health", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.health.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.ishaat && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-success card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">book</i>
                                  </div>
                                  <p className="card-category">Ishaat</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("ishaat", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.ishaat.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("ishaat", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.ishaat.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.new_convert && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-rose card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">face</i>
                                  </div>
                                  <p className="card-category">Nau - Mubaiyeen</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("new_convert", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted :{" "}
                                      {data.new_convert.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData(
                                      "new_convert",
                                      "not_submitted"
                                    )
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.new_convert.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.social && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">public</i>
                                  </div>
                                  <p className="card-category">KHIDMAT E KHALQ</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("social", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.social.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("social", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.social.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.student && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-warning card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">person</i>
                                  </div>
                                  <p className="card-category">UMOOR E TULABA</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("student", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.student.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("student", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.student.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.taleem && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-success card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">card_travel</i>
                                  </div>
                                  <p className="card-category">Taleem</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("taleem", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.taleem.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("taleem", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.taleem.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.tarbiyya && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-rose card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">event</i>
                                  </div>
                                  <p className="card-category">Tarbiyya</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("tarbiyya", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.tarbiyya.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("tarbiyya", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.tarbiyya.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {data.trade && (
                            <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="card card-stats">
                                <div className="card-header card-header-info card-header-icon">
                                  <div className="card-icon">
                                    <i className="material-icons">group_work</i>
                                  </div>
                                  <p className="card-category">SANAT-O-TIJARAT</p>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("trade", "submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-success">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Submitted : {data.trade.submitted.length}
                                    </a>
                                  </div>
                                </div>
                                <div
                                  className="card-footer"
                                  onClick={() =>
                                    this.setModalData("trade", "not_submitted")
                                  }
                                >
                                  <div className="stats">
                                    <i className="material-icons text-danger">
                                      info_outline
                                    </i>
                                    <a href="#">
                                      Not Submitted :{" "}
                                      {data.trade.not_submitted.length}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}


                          <Modal
                            isOpen={this.state.modal}
                            toggle={this.toggle}
                            className={this.props.className}
                          >
                            {modalData && (
                              <div >
                                <ReactToPrint
                                  trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <a href="#" className={"btn btn-danger "}>Print!</a>;
                                  }}
                                  content={() => this.componentRef}
                                />

                                <div class="clearfix"></div>

                                <div style={{ display: 'none' }}>
                                  <ComponentToPrintDasboardModal data={modalData} ref={el => (this.componentRef = el)} level={this.getLevel(level.toString())} year={year} month={moment()
                                    .month(month - 1)
                                    .format("MMMM")} office={modalOffice} type={modalType} />
                                </div>

                              </div>
                            )}
                            <ModalHeader toggle={this.toggle}>
                              {this.getLevel(level.toString())} that have{" "}
                              {modalType} {modalOffice} report for{" "}
                              {moment()
                                .month(month - 1)
                                .format("MMMM")}
                              , {year}
                            </ModalHeader>
                            <ModalBody>
                              {modalData && (
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead className=" text-primary">
                                      <tr>
                                        <th>ID</th>
                                        <th>{this.getLevel(level.toString())}</th>
                                        {modalType === "submitted" && (
                                          <th>Action</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {modalData.map((item, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item.name}</td>
                                          {modalType === "submitted" && (
                                            <Button
                                              color="primary"

                                              onClick={() => {
                                                this.showReport(item.id, item.name)
                                              }}>
                                              View
                                            </Button>
                                            // <Link
                                            //     to={"/report?month=" + month + "&year="+year + "&level="+level +"&id="+item.id + "&office=" +modalOffice}
                                            //     className={"btn btn-primary text-white"}
                                            //
                                            // >
                                            //   View
                                            // </Link>
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </ModalBody>
                            <ModalFooter>
                              <Button color="primary" onClick={this.toggle}>
                                Close
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                :

                <ViewReport office={modalOffice} level={level} year={year} month={month} post_id={id} close={() => this.setState({
                  viewReport: false
                })} name={name} />


              }

            </div>
          )}
        </section>
      </MuiThemeProvider>
    );
  }
}

ReportDashboard.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.person.user,
  isLoggedIn: state.person.isLoggedIn,
});

export default connect(mapStateToProps, {
  logOutUser,
})(ReportDashboard);


export class ComponentToPrintDasboard extends React.PureComponent {
  render() {
    const { data, level, year, month } = this.props;
    return (
      <div>
        <h3>{level} submission report in {month} {year}</h3>
        <table className="table">
          <thead className=" text-primary">
            <tr>
              <th>ID</th>
              <th>Office</th>
              <th>Submitted</th>
              <th>Not Submitted</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Atfal</td>
              <td>{data.atfal.submitted.length}</td>
              <td>{data.atfal.not_submitted.length}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Muhasib</td>
              <td>{data.audit.submitted.length}</td>
              <td>{data.audit.not_submitted.length}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Umoomi</td>

              <td>{data.general.submitted.length}</td>
              <td>{data.general.not_submitted.length}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>SEH'HAT -E- JISMANI</td>

              <td>{data.health.submitted.length}</td>
              <td>{data.health.not_submitted.length}</td>

            </tr>


            <tr>
              <td>5</td>
              <td>Maal</td>

              <td>{data.maal.submitted.length}</td>
              <td>{data.maal.not_submitted.length}</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Waqar e amal</td>

              <td>{data.waqaramal.submitted.length}</td>
              <td>{data.waqaramal.not_submitted.length}</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Nau mubayeen</td>

              <td>{data.new_convert.submitted.length}</td>
              <td>{data.new_convert.not_submitted.length}</td>

            </tr>
            <tr>
              <td>8</td>
              <td>A'itmad</td>
              <td>{data.secretariat.submitted.length}</td>
              <td>{data.secretariat.not_submitted.length}</td>

            </tr>
            <tr>
              <td>9</td>
              <td>KHIDMAT E KHALQ</td>
              <td>{data.social.submitted.length}</td>
              <td>{data.social.not_submitted.length}</td>

            </tr>

            <tr>
              <td>10</td>
              <td>UMOOR E TULABA</td>
              <td>{data.student.submitted.length}</td>
              <td>{data.student.not_submitted.length}</td>

            </tr>
            <tr>
              <td>11</td>
              <td>Tabligh</td>
              <td>{data.tabligh.submitted.length}</td>
              <td>{data.tabligh.not_submitted.length}</td>

            </tr>
            <tr>
              <td>12</td>
              <td>Tajneed</td>
              <td>{data.tajnid.submitted.length}</td>
              <td>{data.tajnid.not_submitted.length}</td>

            </tr>
            <tr>
              <td>13</td>
              <td>Taleem</td>
              <td>{data.taleem.submitted.length}</td>
              <td>{data.taleem.not_submitted.length}</td>

            </tr>
            <tr>
              <td>14</td>
              <td>Tarbiyya</td>
              <td>{data.tarbiyya.submitted.length}</td>
              <td>{data.tarbiyya.not_submitted.length}</td>

            </tr>
            <tr>
              <td>15</td>
              <td>SANAT-O-TIJARAT</td>
              <td>{data.trade.submitted.length}</td>
              <td>{data.trade.not_submitted.length}</td>
            </tr>
            <tr>
              <td>16</td>
              <td>Ishaat</td>
              <td>{data.ishaat.submitted.length}</td>
              <td>{data.ishaat.not_submitted.length}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}




export class ComponentToPrintDasboardModal extends React.PureComponent {
  render() {
    const { data, office, year, month, type, level } = this.props;
    return (
      <div className="table-responsive">
        <h2>{level} that have {type} {office}  for {month} {year}</h2>
        <table className="table">
          <thead className=" text-primary">
            <tr>
              <th>ID</th>
              <th>{level}</th>

            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}