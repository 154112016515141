import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk'
import { rootReducer } from './reducers'



// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export default () => {
//   let store = createStore(persistedReducer)
//   let persistor = persistStore(store)
//   return { store, persistor }
// }

const initialState = {};

const middleware = [thunk];

function saveToLocal(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        console.log(e)
    }
}

function loadFromLocal() {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        console.log(e)
        return undefined
    }
}
const persistedState = loadFromLocal();

const store = createStore(
    rootReducer,
    persistedState,
      applyMiddleware(...middleware),
    // compose(
    //     applyMiddleware(...middleware),
    //     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    //
    // )
);


store.subscribe(() => saveToLocal(store.getState()))

export default store;
