import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class MaalTable extends Component {
    render() {
        const {data} = this.props;
        return (
            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td> Annual Budget</td>
                    <td>{data.annual_budget}</td>
                </tr>
                <tr>
                    <td>
                        Were there any awareness program on Maal
                        observed?
                    </td>
                    <td>
                        {data.awareness
                            ? "Yes"
                            : "No"}
                    </td>
                </tr>
                <tr>
                    <td>Chanda</td>
                    <td>{data.chanda}</td>
                </tr>
                <tr>
                    <td>Operation 220</td>
                    <td>{data.op_220}</td>
                </tr>
                <tr>
                    <td>Tahrik - e - jadid</td>
                    <td>{data.tahrik}</td>
                </tr>
                <tr>
                    <td>Review of religion</td>
                    <td>{data.review}</td>
                </tr>

                <tr>
                    <td>Ijtema</td>
                    <td>{data.ijtema}</td>
                </tr>

                <tr>
                    <td>Others</td>
                    <td>{data.other}</td>
                </tr>
                <tr>
                    <td>
                        Has detailed Maal report been attached
                        to this report ?
                    </td>
                    <td>
                        {data.details_att
                            ? "Yes"
                            : "No"}
                    </td>
                </tr>

                <tr>
                    <td>Attachment (Maal Report)</td>
                    <td>
                        {!data.attachment || data.attachment === ""
                            ? "None"
                            : "Submitted"}
                    </td>
                </tr>
                <tr>
                    <td>Nazim Maal's phone number</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }
}

export default MaalTable;
