import React, { Component } from "react";

import { connect } from "react-redux";
import { createUser, logOutUser } from "../../actions/postAction";
import PropTypes from "prop-types";

class Default extends Component {
  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  componentDidMount() {
    if (this.props.isLoggedIn) {
      window.location.replace("/dashboard");
    }
  }
  render() {
    return (
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100 p-t-30 p-b-30">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Default.propTypes = {
  createUser: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  user: state.person.user,
  isLoggedIn: state.person.isLoggedIn
});

export default connect(mapStateToProps, { createUser, logOutUser })(Default);

// import React,{ Component } from 'react';
// import { BrowserRouter as Router, Route, Switch, NavLink} from 'react-router-dom';
// import Home from '../Home';
// import { Nav, Navbar,NavDropdown} from 'react-bootstrap';
// import {GetStarted} from '../GetStarted';
// import {Secretariat} from '../Secretariat';
// import Script from 'react-load-script'

// import { connect } from 'react-redux';
// import { createUser,logOutUser } from '../../actions/postAction';
// import PropTypes  from 'prop-types';

// import {Census} from '../Census';
// import Tarbiyyah from '../Tarbiyyah';
// import {NoMatch} from '../NoMatch';
// import axios from 'axios';
// import '../../mystyle.css';
// import '../../style.css';

// class Default extends Component {

//   //WARNING! To be deprecated in React v17. Use componentDidMount instead.

// render() {
//     return (

//           <div>

//           <div className="site-wrap">

// <div className="site-mobile-menu site-navbar-target">
//   <div className="site-mobile-menu-header">
//     <div className="site-mobile-menu-close mt-3">
//       <span className="icon-close2 js-menu-toggle"></span>

//     </div>
//   </div>
//   <div className="site-mobile-menu-body ">
//   <ul className="site-nav-wrap">
//             <li><NavLink to="/" className="nav-link " activeClassName="active" exact >Home</NavLink></li>
//             {this.props.isLoggedIn ?
//             <li><a href="/DASHBOARD" className="nav-link" activeClassName="active">Dashboard </a></li>
//             : ''}
//             {/* <li><NavLink to="/SECRETARIAT" className="nav-link" activeClassName="active">ETEMA’AD </NavLink></li>
//             <li><NavLink to="/TAJNEED" className="nav-link" activeClassName="active">TAJNEED</NavLink></li>
//             <li><NavLink to="/TARBIYYAH" className="nav-link" activeClassName="active">TARBIYYAH</NavLink></li> */}

//           </ul>
//           <ul className="site-nav-wrap"><li className="cta"><NavLink to="/" className="nav-link" ><span>Contact Us</span></NavLink></li></ul>

//   </div>
// </div>

// <header className="site-navbar py-4 js-sticky-header site-navbar-target" role="banner">

//   <div className="container-fluid">
//     <div className="d-flex align-items-center">
//       <div className="site-logo mr-auto w-25">
//       <Navbar.Brand href="/">
//     <img
//         src="/logo.jpeg"
//         width="50"
//         height="50"
//         className="d-inline-block mr-3 ml-3 logo-image"
//         alt="Mkan"
//         id="mkanlogo"

//     />
//         <span>MKAN REPORT</span></Navbar.Brand>
//         </div>

//       <div className="mx-auto text-center">
//         <nav className="site-navigation position-relative text-right" role="navigation">
//           <ul className="site-menu main-menu js-clone-nav mx-auto d-none d-lg-block  m-0 p-0">
//             <li><NavLink to="/" className=" " activeClassName="active" exact >Home</NavLink></li>
//             {this.props.isLoggedIn ?
//             <li><a href="/DASHBOARD"  className="nav-link" activeClassName="active">Dashboard </a></li>
//                 : ''}
//             {/* <li><NavLink to="/SECRETARIAT" className="nav-link" activeClassName="active">ETEMA’AD </NavLink></li>
//             <li><NavLink to="/TAJNEED" className="nav-link" activeClassName="active">TAJNEED</NavLink></li>
//             <li><NavLink to="/TARBIYYAH" className="nav-link" activeClassName="active">TARBIYYAH</NavLink></li>
//      */}
//           </ul>

//         </nav>
//       </div>

//       <div className="ml-auto w-25">
//         <nav className="site-navigation position-relative text-right" role="navigation">
//           <ul className="site-menu main-menu site-menu-dark js-clone-nav mr-auto d-none d-lg-block m-0 p-0">
//             <li className="cta"><NavLink to="" className="nav-link"><span>Contact Us</span></NavLink></li>
//           </ul>
//         </nav>
//         <NavLink to="#" className="d-inline-block d-lg-none site-menu-toggle js-menu-toggle text-black float-right"><span className="icon-menu h3"></span></NavLink>
//       </div>
//     </div>
//   </div>

// </header>
// {this.props.children}

// </div>
// <footer className="footer-section bg-white">
//       <div className="container">
//         {/* <div className="row">
//           <div className="col-md-4">
//             <h3>About MKAN</h3>
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro consectetur ut hic ipsum et veritatis corrupti. Itaque eius soluta optio dolorum temporibus in, atque, quos fugit sunt sit quaerat dicta.</p>
//           </div>

//           <div className="col-md-3 ml-auto">
//             <h3>Links</h3>
//             <ul className="list-unstyled footer-links">
//               <li><a href="#">Home</a></li>
//               <li><a href="#">Courses</a></li>
//               <li><a href="#">Programs</a></li>
//               <li><a href="#">Teachers</a></li>
//             </ul>
//           </div>

//           <div className="col-md-4">
//             <h3>Subscribe</h3>
//             <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt incidunt iure iusto architecto? Numquam, natus?</p>
//             <form action="#" className="footer-subscribe">
//               <div className="d-flex mb-5">
//                 <input type="text" className="form-control rounded-0" placeholder="Email"/>
//                 <input type="submit" className="btn btn-primary rounded-0" value="Subscribe"/>
//               </div>
//             </form>
//           </div>

//         </div> */}

//         <div className="row pt-1 mt-1 text-center">
//           <div className="col-md-12">
//             <div className="border-top pt-5">
//             <p>

//         Copyright &copy; {new Date().getFullYear()} All rights reserved | <a href="https://khuddam.ng/" target="new">MKAN NIGERIA</a>

//       </p>
//             </div>
//           </div>

//         </div>
//       </div>
//     </footer>

//     <Script
//       url="/js/jquery-3.3.1.min.js"

//     />
//         <Script
//       url="/js/jquery-migrate-3.0.1.min.js"

//     />
//            <Script
//       url="/js/jquery-ui.js"

//     />

// <Script
//       url="/js/popper.min.js"

//     />
//         <Script
//       url="/js/bootstrap.min.js"

//     />
//            <Script
//       url="/js/owl.carousel.min.js"

//     />
//                <Script
//       url="/js/jquery.stellar.min.js"

//     />
//                    <Script
//       url="/js/jquery.countdown.min.js"

//     />
//                    <Script
//       url="/js/bootstrap-datepicker.min.js"

//     />
//                        <Script
//       url="/js/jquery.easing.1.3.js"

//     />
//                           <Script
//       url="/js/aos.js"

//     />

// <Script
//       url="/js/jquery.fancybox.min.js"

//     />
//                        <Script
//       url="/js/jquery.sticky.js"

//     />
//                           <Script
//       url="/js/main.js"

//     />

//           </div>

//   );
// }
// }

// Default.propTypes = {
//   createUser: PropTypes.func.isRequired,
//   logOutUser: PropTypes.func.isRequired,
//   user: PropTypes.object.isRequired,
//   isLoggedIn: PropTypes.bool.isRequired
// }
// const mapStateToProps = state => ({
//   user: state.person.user,
//   isLoggedIn: state.person.isLoggedIn
// });

// export default connect(mapStateToProps, {createUser, logOutUser})(Default);
