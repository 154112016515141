import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUser, logOutUser } from "../actions/postAction";

import axios from "axios";
import { BASE_URL } from "../constant";
import Swal from "sweetalert2";
import { NetworkError } from "./NetworkError";

import { endpoints, http } from "../configs/https";
import Moment from "react-moment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme } from "@material-ui/core";

import moment from "moment";
import "moment-timezone";
import {
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    TablePagination,
} from "@material-ui/core";

import { CSVLink, CSVDownload } from "react-csv";
import { Loading } from "./Loading";
import ReactToPrint from 'react-to-print';
import MaalTable from "./officeTable/MaalTable";
import NewConvert from "./officeTable/NewConvert";
import Secretariat from "./officeTable/Secretariat";
import Social from "./officeTable/Social";
import Student from "./officeTable/Student";
import Tabligh from "./officeTable/Tabligh";
import Tarbiyya from "./officeTable/Tarbiyya";
import Trade from "./officeTable/Trade";
import Taleem from "./officeTable/Taleem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#229858",
            light: "#229858",
            dark: "#229858",
        },
        secondary: {
            main: "#229858",
        },
    },
});
const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

export const YEARS = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    for (var i = (currentYear - 10); i <= currentYear; i++) {
        years.push(i);
    }
    return years;
}


class UserHome extends Component {
    constructor() {
        super();
        var d = new Date();
        var n = d.getFullYear();
        var m = d.getMonth() + 1;
        this.state = {
            page: 1,
            data: [],
            rowsPerPage: 10,
            loading: false,

            totalPages: 0,
            open: false,
            totalElements: 0,
            office: "tajnid",
            month: m !== 1 ? m - 1 : m,
            showFilter: true,
            year: n,
            level: 1,
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
    }

    handleChangeEvent(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }

    // constructor() {
    //   super();
    //   this.state = {
    //     data: [{ row: "Title", row1: 30 }],
    //     data2: {},
    //     networkError: false,
    //     url: "https://jsonplaceholder.typicode.com/posts",
    //     url2: "https://jsonplaceholder.typicode.com/posts/1"
    //   };
    //
    //   this.checkStatus = this.checkStatus.bind(this);
    //   this.getHeader = this.getHeader.bind(this);
    //   this.getRowsData = this.getRowsData.bind(this);
    //   this.getKeys = this.getKeys.bind(this);
    //   this.handleSubmit = this.handleSubmit.bind(this);
    //   this.handleSubmit2 = this.handleSubmit2.bind(this);
    //   this.handleChange = this.handleChange.bind(this);
    // }
    componentDidMount() {
        document.title = "Dashboard - MKAN REPORT";
        // const {page,rowsPerPage} = this.state;
        // this.fetchQuery(page, rowsPerPage);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        axios
            .get(this.state.url, {
                headers: { Authorization: `Bearer ${this.props.user.token}` },
            })
            .then((res) => {
                //    if(res.status == 201){
                //    }
                let wholeArray = Object.keys(res.data).map((key) => res.data[key]);

                this.setState({
                    data: wholeArray,
                });
                console.log(res);
            })
            .catch((error) => {
                if (error.message === "Network Error") {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    this.setState({
                        networkError: true,
                    });

                    Toast.fire({
                        type: "warning",
                        title:
                            "Network error, try Reloading page. Possibly no internet connection ",
                    });
                } else {
                    if (error.response.status === 401) {
                    } else {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                        });

                        Toast.fire({
                            type: "error",
                            title: "Something went wrong, try again.",
                        });
                    }
                }
            });
    }

    handleSubmit2(event) {
        event.preventDefault();
        axios
            .get(this.state.url2, {
                headers: { Authorization: `Bearer ${this.props.user.token}` },
            })
            .then((res) => {
                //    if(res.status == 201){
                //    }

                this.setState({
                    data2: res.data,
                });
                console.log(res);
            })
            .catch((error) => {
                if (error.message === "Network Error") {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    this.setState({
                        networkError: true,
                    });

                    Toast.fire({
                        type: "warning",
                        title:
                            "Network error, try Reloading page. Possibly no internet connection ",
                    });
                } else {
                    if (error.response.status === 401) {
                    } else {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                        });

                        Toast.fire({
                            type: "error",
                            title: "Something went wrong, try again.",
                        });
                    }
                }
            });
    }

    // componentDidMount() {
    //   const {page,rowsPerPage} = this.state;
    //   this.fetchMembers(page, rowsPerPage);
    // }

    fetchQuery(page, rowscount) {
        this.setState({
            loading: true,
            showFilter: false,
            data: [],
        });
        const { office, month, year, level } = this.state;

        let queryString = "";

        if (month !== 0) {
            queryString += "&month=" + month;
        }

        console.log(year);
        if (year !== 0) {
            queryString += "&year=" + year;
        }

        if (level !== 0) {
            queryString += "&report_level=" + level;
        }

        http
            .get(
                endpoints.QUERY_REPORT.replace(":page", page).replace(
                    ":office",
                    office
                ) + queryString
            )
            .then((response) => {
                console.log("hello");
                console.log(response.data);
                this.setState({
                    loading: false,
                    data: response.data,

                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage,
                });
            });
    }

    getOne = function () {
        var keys = this.getKeys();
        return keys.map((key, index) => {
            return <th key={key}>{key.toUpperCase()}</th>;
        });
    };

    getKeysOne = function () {
        return Object.keys(this.state.data2);
    };

    getRowsData2 = function () {
        var items = this.state.data2;
        var keys = this.getKeysOne();
        return keys.map((row, index) => {
            return (
                <tr key={index}>
                    <RenderRow2 key={index} data={row} items={items} />
                </tr>
            );
        });
    };
    getKeys = function () {
        return Object.keys(this.state.data[0]);
    };

    getHeader = function () {
        var keys = this.getKeys();
        return keys.map((key, index) => {
            return <th key={key}>{key.toUpperCase()}</th>;
        });
    };
    getRowsData = function () {
        var items = this.state.data;
        var keys = this.getKeys();
        return items.map((row, index) => {
            return (
                <tr key={index}>
                    <RenderRow key={index} data={row} keys={keys} />
                </tr>
            );
        });
    };

    // getRowsData = function() {
    //   const {data} = this.props.recentPayments;
    //   return data.map((item, index) => {
    //     return (
    //         <tr key={index}>
    //           {/*<td>{index + 1}</td>*/}
    //           <td>{`₦${thousand(parseInt(item.amount))}`}</td>
    //
    //           <td>    {item.paymentRequestType} {item.paymentType.name} Payment </td>
    //           <td>           <span
    //               style={
    //
    //                 {color: this.getColor(item.status)}
    //               }>
    //                       {item.status}
    //                   </span>
    //           </td>
    //           <td>   {moment(item.dateOf).format('MMMM Do YYYY')}</td>
    //
    //
    //         </tr>
    //     );
    //   });
    // };

    // <div className="card-body">
    // {this.state.tajneed === null ? (
    //     <div className="text-center">
    //       <h2>No Report found</h2>
    //     </div>
    // ) : (
    //     <table className="table">
    //       <thead className=" text-primary">
    //       <tr>
    //         <th>ID</th>
    //         <th>Field</th>
    //         <th>Response</th>
    //       </tr>
    //       </thead>
    //       <tbody>
    //       <tr>
    //         <td>1</td>
    //         <td>Submitted By</td>
    //         <td>{this.state.tajneed.submitted_by}</td>
    //       </tr>
    //       <tr>
    //         <td>2</td>
    //         <td>Total Number of Khuddam</td>
    //         <td>{this.state.tajneed.total_khudam}</td>
    //       </tr>
    //       <tr>
    //         <td>3</td>
    //         <td>Total Number of Khuddam at 40</td>
    //         <td>{this.state.tajneed.khudam_at_40}</td>
    //       </tr>
    //       <tr>
    //         <td>4</td>
    //         <td>
    //           Total Number of Khuddam with Uniform
    //         </td>
    //         <td>
    //           {this.state.tajneed.total_with_uniform}
    //         </td>
    //       </tr>
    //       <tr>
    //         <td>5</td>
    //         <td>Total Number of Atfal</td>
    //         <td>{this.state.tajneed.total_atfal}</td>
    //       </tr>
    //       <tr>
    //         <td>6</td>
    //         <td>Nazim Tajneed's Phone Number</td>
    //         <td>{this.state.tajneed.phone}</td>
    //       </tr>
    //       </tbody>
    //     </table>
    // )}
    // </div>

    checkStatus() {
        axios
            .get(BASE_URL + `status/`, {
                headers: { Authorization: `Bearer ${this.props.user.token}` },
            })
            .then((res) => {
                //    if(res.status == 201){
                //    }
            })
            .catch((error) => {
                if (error.message === "Network Error") {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    this.setState({
                        networkError: true,
                    });

                    Toast.fire({
                        type: "warning",
                        title:
                            "Network error, try Reloading page. Possibly no internet connection ",
                    });
                } else {
                    if (error.response.status === 401) {
                        this.props.logOutUser();
                        window.location.replace("/");
                        this.setState({});
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                        });

                        Toast.fire({
                            type: "error",
                            title: "You need to log in again ",
                        });
                    } else {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                        });

                        Toast.fire({
                            type: "error",
                            title: "Something went wrong, try again.",
                        });
                    }
                }
            });

        axios
            .get(
                BASE_URL + `qaid/`,

                { headers: { Authorization: `Bearer ${this.props.user.token}` } }
            )
            .then((res) => {
                //    if(res.status == 201){
                console.log(res);
                console.log(res.data);
                // this.setState({
                //   data: res.data
                // });
                //  console.log()
                let wholeArray = Object.keys(res.data).map((key) => res.data[key]);

                Object.entries(wholeArray).map(([key, value]) =>
                    console.log(key + ":" + value)
                );

                Object.keys(wholeArray).forEach(function (key) {
                    var value = res.data[key];
                    console.log(key + ":" + value);
                });
                this.setState({
                    data: wholeArray,
                });

                //    }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
        this.fetchQuery(newPage, this.state.rowsPerPage);
    };

    handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(+event.target.value);
        this.setState({
            rowsPerPage: +event.target.value,
        });

        this.fetchQuery(this.state.page, +event.target.value);
    };


    applyFilter() {
        this.fetchQuery(1, this.state.rowsPerPage);
        this.setState({
            page: 1,
        });
    }

    setupLevel() {
        if (this.props.user.state) {
            this.setState({
                level: 0,
            });
        }
    }

    render() {
        const {
            page,
            rowsPerPage,
            data,
            totalElements,
            office,
            showFilter,
            month,
            year,
            loading,
            level,
        } = this.state;

        return (
            <MuiThemeProvider theme={customTheme}>
                <div>

                    {showFilter ? (
                        <div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                                    <FormControl className={"form-control"}>
                                        <InputLabel id="demo-simple-select-label">
                                            Office
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={office}
                                            name={"office"}
                                            onChange={this.handleChangeEvent}
                                        >
                                            <MenuItem value={"tajnid"}>Tajneed</MenuItem>
                                            <MenuItem value={"taleem"}>Taleem</MenuItem>
                                            <MenuItem value={"tarbiyya"}>Tarbiyya</MenuItem>
                                            <MenuItem value={"tabligh"}>Tabligh</MenuItem>
                                            <MenuItem value={"atfal"}>Atfal</MenuItem>
                                            <MenuItem value={"audit"}>Muhasb</MenuItem>
                                            <MenuItem value={"general"}>Umoomi</MenuItem>
                                            <MenuItem value={"health"}>SEH'HAT -E- JISMANI</MenuItem>
                                            <MenuItem value={"ishaat"}>Ishaat</MenuItem>
                                            <MenuItem value={"waqaramal"}>Waqar Amal</MenuItem>
                                            <MenuItem value={"maal"}>Maal</MenuItem>
                                            <MenuItem value={"new_convert"}>Nou - Mubaiyeen</MenuItem>
                                            <MenuItem value={"secretariat"}>A'ITMAD</MenuItem>
                                            <MenuItem value={"social"}>KHIDMAT E KHALQ</MenuItem>
                                            <MenuItem value={"student"}>UMOOR E TULABA</MenuItem>
                                            <MenuItem value={"trade"}>SANAT-O-TIJARAT</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                                    <FormControl className={"form-control"}>
                                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={month}
                                            name={"month"}
                                            onChange={this.handleChangeEvent}
                                        >
                                            <MenuItem value={0}>All</MenuItem>
                                            <MenuItem value={1}>Jan</MenuItem>
                                            <MenuItem value={2}>Feb</MenuItem>
                                            <MenuItem value={3}>Mar</MenuItem>
                                            <MenuItem value={4}>Apr</MenuItem>
                                            <MenuItem value={5}>May</MenuItem>
                                            <MenuItem value={6}>Jun</MenuItem>
                                            <MenuItem value={7}>Jul</MenuItem>
                                            <MenuItem value={8}>Aug</MenuItem>
                                            <MenuItem value={9}>Sep</MenuItem>
                                            <MenuItem value={10}>Oct</MenuItem>
                                            <MenuItem value={11}>Nov</MenuItem>
                                            <MenuItem value={12}>Dec</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5">

                                    <FormControl className={"form-control"}>
                                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={year}
                                            name={"year"}
                                            onChange={this.handleChangeEvent}
                                        >
                                            <MenuItem value={0}>All</MenuItem>
                                            {YEARS().reverse().map((option, index) => (
                                                <MenuItem value={option}>{option}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                                    <FormControl className={"form-control"}>
                                        <InputLabel id="demo-simple-select-label">Level</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={level}
                                            name={"level"}
                                            onChange={this.handleChangeEvent}
                                        >
                                            {/*<MenuItem value={0}>All</MenuItem>*/}
                                            <MenuItem value={3}>Muqami</MenuItem>
                                            <MenuItem value={2}>Dila</MenuItem>
                                            <MenuItem value={1}>Ilaqa</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <button
                                onClick={() => this.applyFilter()}
                                className="btn btn-success mt-5"
                            >
                                Apply Filter
                            </button>
                        </div>
                    ) : (
                        <div>
                            {loading ? (
                                <div className="text-center container">
                                    <center>
                                        <Loading />
                                    </center>
                                </div>
                            ) : (

                                <div>
                                    <button
                                        onClick={() =>
                                            this.setState({
                                                showFilter: true,
                                            })
                                        }
                                        className="btn btn-success mt-5"
                                    >
                                        Show Filter
                                    </button>

                                    {data.length === 0 &&
                                        !loading &&
                                        this.state.totalElements === 0 && (
                                            <h2>No report found for this selection</h2>
                                        )}
                                    {data.length > 0 && (
                                        <div>
                                            <div>

                                                <ReactToPrint
                                                    documentTitle={office + "Report"}
                                                    pageStyle={pageStyle}
                                                    trigger={() => {
                                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                        // to the root node of the returned component as it will be overwritten.
                                                        return <a href="#" className={"btn btn-danger"}>PDF</a>;
                                                    }}
                                                    content={() => this.componentRef}
                                                />


                                                <CSVLink
                                                    data={data}
                                                    filename={office + ".csv"}
                                                    className="btn btn-info"
                                                    style={{ marginLeft: 10 }}
                                                    target="_blank"
                                                >
                                                    CSV
                                                </CSVLink>
                                            </div>
                                            <ComponentToPrint office={office} data={data}
                                                ref={el => (this.componentRef = el)} month={month}
                                                year={year} level={level} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </MuiThemeProvider>
            // <div>
            //   <form onSubmit={this.handleSubmit}>
            //     // try this also https://jsonplaceholder.typicode.com/posts/1/comments
            //     also try <br />
            //     // try this url also http://209.105.243.210:8800/api/v1/qaid/
            //     <input
            //       placeholder="Enter dynamic api to fetch from"
            //       className="form-control"
            //       name="url"
            //       value={this.state.url}
            //       onChange={this.handleChange}
            //     />
            //     <button className="btn btn-success" type="submit">
            //       Get
            //     </button>
            //   </form>
            //   <table className="table">
            //     <thead>
            //       <tr>{this.getHeader()}</tr>
            //     </thead>
            //     <tbody>{this.getRowsData()}</tbody>
            //   </table>
            //   <h2>Post full details</h2>
            //   // try this url also http://209.105.243.210:8800/api/v1/status
            //   <form onSubmit={this.handleSubmit2}>
            //     <input
            //       placeholder="Enter dynamic api to fetch from"
            //       className="form-control"
            //       name="url2"
            //       value={this.state.url2}
            //       onChange={this.handleChange}
            //     />
            //     <button className="btn btn-success" type="submit">
            //       Get
            //     </button>
            //   </form>
            //   <table className="table">
            //     <tbody>{this.getRowsData2()}</tbody>
            //   </table>
            // </div>
        );
    }
}

export class ComponentToPrint extends React.PureComponent {

    getLevel = function (level) {
        if (level === "1") {
            return "Ilaqa";
        } else if (level === "2") {
            return "Dila";
        } else if (level === "3") {
            return "Muqami";
        }

    };

    getHeading = function (details, level) {

        if (level === "1") {
            return details.state + " Ilaqa";
        } else if (level === "2") {
            return details.dila + " Dila";
        } else if (level === "3") {
            return details.muqami + " Muqami";
        }

    };

    aggregateData = function () {
        const { office, data, month, year, level } = this.props;
        var holder = {};
        if (office === "tajnid") {

            holder = { total_khudam: 0, khudam_at_40: 0, total_with_uniform: 0, total_atfal: 0 }
            data.forEach(function (d) {


                holder.total_khudam = holder.total_khudam + d.total_khudam;
                holder.khudam_at_40 += d.khudam_at_40
                holder.total_with_uniform += d.total_with_uniform
                holder.total_atfal += d.total_atfal

            });
        } else if (office === "taleem") {
            holder = { t_number_classes: 0, session: 0, book_review: 0, ict: 0, exam_prep: 0, total: 0, reviewed: 0 }
            data.forEach(function (d) {


                holder.t_number_classes += d.t_number_classes
                holder.session += d.session
                holder.book_review += d.book_review
                holder.ict += d.ict
                holder.exam_prep += d.exam_prep
                holder.total += d.total
                holder.reviewed += d.reviewed


            });
        } else if (office === "atfal") {
            holder = { atfal: 0, session: 0, organised_yes: 0, organised_no: 0, no_camp: 0, no_atfal: 0, no_khudam: 0 }

            data.forEach(function (d) {


                holder.atfal += d.atfal
                holder.session += d.session

                if (d.organised) {
                    holder.organised_yes += 1
                } else {
                    holder.organised_no += 1
                }
                holder.no_camp += d.no_camp
                holder.no_atfal += d.no_atfal
                holder.no_khudam += d.no_khudam


            });

        } else if (office === "audit") {
            holder = { income_yes: 0, income_no: 0, quarterly_yes: 0, quarterly_no: 0, ledgers_yes: 0, ledgers_no: 0 }

            data.forEach(function (d) {


                if (d.income) {
                    holder.income_yes += 1
                } else {
                    holder.income_no += 1
                }
                if (d.quarterly) {
                    holder.quarterly_yes += 1
                } else {
                    holder.quarterly_no += 1
                }
                if (d.ledgers) {
                    holder.ledgers_yes += 1
                } else {
                    holder.ledgers_no += 1
                }


            });
        } else if (office === "general") {
            holder = {
                jummat_yes: 0,
                jummat_no: 0,
                eid_yes: 0,
                eid_no: 0,
                ijtema_yes: 0,
                ijtema_no: 0,
                jalsa_yes: 0,
                jalsa_no: 0,
                other_yes: 0,
                other_no: 0,
                report_sent_yes: 0,
                report_sent_no: 0,
                special_squad: 0,
                training: 0,
                attendance: 0,
                driver: 0,
                nycn1_yes: 0,
                nycn1_no: 0,
                nycn2: 0
            }

            data.forEach(function (d) {

                if (d.jummat) {
                    holder.jummat_yes += 1
                } else {
                    holder.jummat_no += 1
                }
                if (d.eid) {
                    holder.eid_yes += 1
                } else {
                    holder.eid_no += 1
                }
                if (d.ijtema) {
                    holder.ijtema_yes += 1
                } else {
                    holder.ijtema_no += 1
                }

                if (d.jalsa) {
                    holder.jalsa_yes += 1
                } else {
                    holder.jalsa_no += 1
                }
                if (d.other) {
                    holder.other_yes += 1
                } else {
                    holder.other_no += 1
                }

                if (d.report_sent) {
                    holder.report_sent_yes += 1
                } else {
                    holder.report_sent_no += 1
                }
                holder.special_squad += d.special_squad
                holder.training += d.training
                holder.attendance += d.attendance
                holder.driver += d.driver
                if (d.nycn1) {
                    holder.nycn1_yes += 1
                } else {
                    holder.nycn1_no += 1
                }
                holder.nycn2 += d.nycn2


            });


        }
        else if (office === "health") {
            holder = { sport_yes: 0, sport_no: 0, matches: 0, matches2: 0, individual: 0, participants: 0, health_talk: 0, screening: 0, particip_k: 0, beneficiaries: 0 }

            data.forEach(function (d) {

                if (d.sport) {
                    holder.sport_yes += 1
                } else {
                    holder.sport_no += 1
                }

                holder.matches += d.matches
                holder.matches2 += d.matches2
                holder.individual += Number(d.individual)
                holder.participants += d.participants
                holder.health_talk += d.health_talk
                holder.screening += d.screening
                holder.particip_k += d.particip_k
                holder.beneficiaries += d.beneficiaries

            });
        }
        else if (office === "ishaat") {
            holder = { truth: 0, review: 0, irfan: 0, websites: 0, periodicals: 0, news_items: 0 }
            data.forEach(function (d) {

                holder.truth += Number(d.truth)
                holder.review += Number(d.review)
                holder.irfan += Number(d.irfan)
                holder.websites += Number(d.websites)
                holder.periodicals += Number(d.periodicals)



            });
        }
        else if (office === "waqaramal") {
            holder = { event: 0, type: "", attendance: 0 }

            data.forEach(function (d) {

                holder.event += Number(d.event)
                holder.type += d.type + ", "
                holder.attendance += Number(d.attendance)




            });
        }

        return holder;

    };


    render() {
        const { office, data, month, year, level } = this.props;
        return (
            <div className="w-100 overflow-auto">
                <h3>{data.length} Reports Found</h3>
                {office === "tajnid" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>

                        <h2>Aggregate Report for all {this.getLevel(level.toString())}</h2>

                        {this.aggregateData() &&

                            <table className="table">

                                <thead className=" text-primary">
                                    <tr>
                                        <th>ID</th>
                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>1</td>
                                        <td>Total Number of Khuddam</td>
                                        <td>{this.aggregateData().total_khudam}</td>
                                    </tr>

                                    <tr>
                                        <td>2</td>
                                        <td>Total Number of Khuddam at 40</td>
                                        <td>{this.aggregateData().khudam_at_40}</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Total Number of Khuddam with Uniform</td>
                                        <td>{this.aggregateData().total_with_uniform}</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Total Number of Atfal</td>
                                        <td>{this.aggregateData().total_atfal}</td>
                                    </tr>
                                </tbody>
                            </table>

                        }


                        <h2>{this.getLevel(level.toString())} Tajneed Report {month === 0 ? " All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? " All Years " : year}</h2>


                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div>
                                {item.user &&
                                    <h3>{this.getHeading(item.user.details, item.report_level)}</h3>
                                }
                                <table className="table" key={index}>

                                    <thead className=" text-primary">
                                        <tr>
                                            <th>ID</th>
                                            <th>Field</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Submitted By</td>
                                            <td>{item.submitted_by} {item.user &&
                                                <span>({item.user.details.surname} {item.user.details.names} - {item.user.details.post})</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Total Number of Khuddam</td>
                                            <td>{item.total_khudam}</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Total Number of Khuddam at 40</td>
                                            <td>{item.khudam_at_40}</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Total Number of Khuddam with Uniform</td>
                                            <td>{item.total_with_uniform}</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Total Number of Atfal</td>
                                            <td>{item.total_atfal}</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Nazim Tajneed's Phone Number</td>
                                            <td>{item.phone}</td>
                                        </tr>

                                        <tr>
                                            <td>7</td>
                                            <td>Month, Year</td>
                                            <td>
                                                {" "}
                                                {moment()
                                                    .month(item.month - 1)
                                                    .format("MMMM")}
                                                , {item.year}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Level</td>
                                            <td> {this.getLevel(item.report_level)}</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Date of Submission</td>
                                            <td>
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY, h:mm a"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}

                {office === "taleem" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>

                        <h2>Aggregate Report for all {this.getLevel(level.toString())}</h2>

                        {this.aggregateData() &&

                            <table className="table">
                                <thead className=" text-primary">
                                    <tr>
                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>
                                            Total number of Taleem classes conducted in
                                            the month
                                        </td>
                                        <td>{this.aggregateData().t_number_classes}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No of Khuddam who participated in Talimul
                                            Quran session
                                        </td>
                                        <td>{this.aggregateData().session}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No of Khuddam who participated in Age Group
                                            Book Review
                                        </td>
                                        <td>{this.aggregateData().book_review}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No of Khuddam who participated in ICT training
                                        </td>
                                        <td>{this.aggregateData().ict}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No of Khuddam who participated in proficiency
                                            exam preparation
                                        </td>
                                        <td>{this.aggregateData().exam_prep}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>{this.aggregateData().total}</td>
                                    </tr>
                                    <tr>
                                        <td>No of books reviewed during this month</td>
                                        <td>{this.aggregateData().reviewed}</td>
                                    </tr>

                                </tbody>
                            </table>


                        }


                        <h2>{this.getLevel(level.toString())} Taleem Report {month === 0 ? " All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? " All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={item}>

                                {item.user &&
                                    <h3>{this.getHeading(item.user.details, item.report_level)}</h3>
                                }
                                <Taleem data={item} />
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}


                {office === "atfal" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>Aggregate Atfal Report for all {this.getLevel(level.toString())}</h2>
                        {this.aggregateData() &&
                            <table className="table">
                                <thead className=" text-primary">
                                    <tr>

                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    <tr>

                                        <td>No. of Atfals</td>
                                        <td>{this.aggregateData().atfal}</td>
                                    </tr>
                                    <tr>

                                        <td>
                                            No. of Atfal sessions held in the month
                                        </td>
                                        <td>{this.aggregateData().session}</td>
                                    </tr>
                                    <tr>

                                        <td>
                                            No of {this.getLevel(level.toString())} where Atfal organisation is duly established
                                        </td>
                                        <td>
                                            {this.aggregateData().organised_yes
                                            }
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            No of {this.getLevel(level.toString())} where Atfal organisation is not duly
                                            established
                                        </td>
                                        <td>
                                            {this.aggregateData().organised_no
                                            }
                                        </td>
                                    </tr>

                                    <tr>

                                        <td>No. of Organized Camp</td>
                                        <td>{this.aggregateData().no_camp}</td>
                                    </tr>
                                    <tr>

                                        <td>No. of Atfal Present</td>
                                        <td>{this.aggregateData().no_atfal}</td>
                                    </tr>

                                    <tr>

                                        <td>No. of Khuddam Present</td>
                                        <td>{this.aggregateData().no_khudam}</td>
                                    </tr>


                                </tbody>
                            </table>


                        }


                        <h2>{this.getLevel(level.toString())} Atfal Report {month === 0 ? " All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? " All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div>

                                {item.user &&
                                    <h3>{this.getHeading(item.user.details, item.report_level)}</h3>
                                }
                                <table className="table" key={index}>
                                    <thead className=" text-primary">
                                        <tr>

                                            <th>Field</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>

                                            <td>Submitted By</td>
                                            <td>{item.submitted_by} {item.user &&
                                                <span>({item.user.details.surname} {item.user.details.names} - {item.user.details.post})</span>}</td>
                                        </tr>
                                        <tr>

                                            <td> No. of Atfals</td>
                                            <td>{item.atfal}</td>
                                        </tr>
                                        <tr>

                                            <td>
                                                No. of Atfal sessions held in the month
                                            </td>
                                            <td>{item.session}</td>
                                        </tr>
                                        <tr>

                                            <td>
                                                Is Atfal organisation duly established?
                                            </td>
                                            <td>
                                                {item.organised
                                                    ? "Yes"
                                                    : "No"}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                If Atfal organisation is not established
                                                , state reasons thereof and also mention
                                                what efforts have been done in this
                                                regard
                                            </td>
                                            <td>{item.establish}</td>
                                        </tr>
                                        <tr>

                                            <td>No. of Organized Camp</td>
                                            <td>{item.no_camp}</td>
                                        </tr>
                                        <tr>

                                            <td>No. of Atfal Present</td>
                                            <td>{item.no_atfal}</td>
                                        </tr>

                                        <tr>

                                            <td>No. of Khuddam Present</td>
                                            <td>{item.no_khudam}</td>
                                        </tr>
                                        <tr>

                                            <td>Attachment (Atfal Report)</td>
                                            <td>
                                                {!item.attachment || item.attachment === ""
                                                    ? "None"
                                                    : "Submitted"}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td> Nazim Atfal's phone number</td>
                                            <td>{item.phone}</td>
                                        </tr>
                                        <tr>

                                            <td>Month, Year</td>
                                            <td>
                                                {" "}
                                                {moment()
                                                    .month(item.month - 1)
                                                    .format("MMMM")}
                                                , {item.year}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>Level</td>
                                            <td> {this.getLevel(item.report_level)}</td>
                                        </tr>
                                        <tr>

                                            <td>Date of Submission</td>
                                            <td>
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY, h:mm a"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}


                {office === "audit" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>

                        <h2>Aggregate Muhasb Report for all {this.getLevel(level.toString())}</h2>
                        {this.aggregateData() &&
                            <table className="table">

                                <thead className=" text-primary">
                                    <tr>

                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td>
                                            No of {this.getLevel(level.toString())} where income and expenditure accounts was
                                            audited this month
                                        </td>
                                        <td>
                                            {this.aggregateData().income_yes}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No of {this.getLevel(level.toString())} where income and expenditure accounts was
                                            not
                                            audited this month
                                        </td>
                                        <td>
                                            {this.aggregateData().income_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No of {this.getLevel(level.toString())} where a copy of the Quarterly Audit Report
                                            was
                                            sent to National Office
                                        </td>
                                        <td>
                                            {this.aggregateData().quarterly_yes}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No of {this.getLevel(level.toString())} where a copy of the Quarterly Audit Report
                                            was not
                                            sent to National Office
                                        </td>
                                        <td>
                                            {this.aggregateData().quarterly_no}
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            No of {this.getLevel(level.toString())} where a Day-book and Ledgers were prescribed
                                            by
                                            National Office being maintained in your
                                            majlis
                                        </td>
                                        <td>
                                            {this.aggregateData().ledgers_yes}
                                        </td>
                                    </tr>
                                    <tr>

                                        <td>
                                            No of {this.getLevel(level.toString())} where a Day-book and Ledgers <strong>were
                                                not</strong> prescribed by
                                            National Office being maintained in your
                                            majlis
                                        </td>
                                        <td>
                                            {this.aggregateData().ledgers_no}
                                        </td>
                                    </tr>


                                </tbody>
                            </table>


                        }


                        <h2>{this.getLevel(level.toString())} Muhasb Report {month === 0 ? " All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? " All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>

                                {item.user &&
                                    <h3>{this.getHeading(item.user.details, item.report_level)}</h3>
                                }
                                <table className="table">

                                    <thead className=" text-primary">
                                        <tr>

                                            <th>Field</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>

                                            <td>Submitted By</td>
                                            <td>{item.submitted_by} {item.user &&
                                                <span>({item.user.details.surname} {item.user.details.names} - {item.user.details.post})</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Was income and expenditure accounts
                                                audited this month?
                                            </td>
                                            <td>
                                                {item.income ? "Yes" : "No"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Was a copy of the Quarterly Audit Report
                                                sent to National Office?
                                            </td>
                                            <td>
                                                {item.quarterly
                                                    ? "Yes"
                                                    : "No"}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                Was a Day-book and Ledgers prescribed by
                                                National Office being maintained in your
                                                majlis?
                                            </td>
                                            <td>
                                                {item.ledgers
                                                    ? "Yes"
                                                    : "No"}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td> Nazim Muhasib's phone number</td>
                                            <td> {item.phone}</td>
                                        </tr>
                                        <tr>

                                            <td>Month, Year</td>
                                            <td>
                                                {moment()
                                                    .month(item.month - 1)
                                                    .format("MMMM")}
                                                , {item.year}
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>Level</td>
                                            <td> {this.getLevel(item.report_level)}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Submission</td>
                                            <td>
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY, h:mm a"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}


                {office === "general" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>


                        <h2>Aggregate Umoomi Report for all {this.getLevel(level.toString())}</h2>
                        {this.aggregateData() &&
                            <table className="table">
                                <thead className=" text-primary">
                                    <tr>
                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    <tr>
                                        <td colSpan={2}>
                                            Whether Khuddam of Majlis offer services as
                                            security guard during Jamaat ceremonies like
                                            Friday Prayer, Id, Ijtema , other meetings
                                            etc.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Jummat - Yes</td>
                                        <td>{this.aggregateData().jummat_yes}</td>
                                    </tr>
                                    <tr>
                                        <td>Jummat - No</td>
                                        <td>{this.aggregateData().jummat_no}</td>
                                    </tr>
                                    <tr>
                                        <td>Eid - Yes</td>
                                        <td>{this.aggregateData().eid_yes}</td>
                                    </tr>
                                    <tr>
                                        <td>Eid - No</td>
                                        <td>{this.aggregateData().eid_no}</td>
                                    </tr>
                                    <tr>
                                        <td>Ijtema - Yes</td>
                                        <td>{this.aggregateData().ijtema_yes}</td>
                                    </tr>
                                    <tr>
                                        <td>Ijtema - No</td>
                                        <td>{this.aggregateData().ijtema_no}</td>
                                    </tr>

                                    <tr>
                                        <td>Jalsa - Yes</td>
                                        <td>{this.aggregateData().jalsa_yes}</td>
                                    </tr>
                                    <tr>
                                        <td>Jalsa - No</td>
                                        <td>{this.aggregateData().jalsa_no}</td>
                                    </tr>
                                    <tr>
                                        <td> Others - Yes</td>
                                        <td>{this.aggregateData().other_yes}</td>
                                    </tr>
                                    <tr>
                                        <td> Others - No</td>
                                        <td>{this.aggregateData().other_no}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Was separate report of the department
                                            sent? - Yes
                                        </td>
                                        <td>{this.aggregateData().report_sent_yes}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Was separate report of the department
                                            sent? - No
                                        </td>
                                        <td>{this.aggregateData().report_sent_no}</td>
                                    </tr>
                                    <tr>
                                        <td>No of khuddam on special squad</td>
                                        <td>
                                            {this.aggregateData().special_squad}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> No of security training held</td>
                                        <td>{this.aggregateData().training}</td>
                                    </tr>
                                    <tr>
                                        <td>No of khuddam in attendance</td>
                                        <td>{this.aggregateData().attendance}</td>
                                    </tr>

                                    <tr>
                                        <td>No of khuddam who can drive</td>
                                        <td>{this.aggregateData().driver}</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Is your Qaidat registered with the NYCN? - Yes
                                        </td>
                                        <td>{this.aggregateData().nycn1_yes}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Is your Qaidat registered with the NYCN? - No
                                        </td>
                                        <td>{this.aggregateData().nycn1_no}</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Attendance at regular NYCN meetings
                                        </td>
                                        <td>{this.aggregateData().nycn2}</td>
                                    </tr>

                                </tbody>
                            </table>


                        }


                        <h2>{this.getLevel(level.toString())} Umoomi Report {month === 0 ? " All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? " All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>

                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <table className="table">
                                    <thead className=" text-primary">
                                        <tr>
                                            <th>Field</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Submitted By</td>
                                            <td>{item.submitted_by} {item.user &&
                                                <span>({item.user.details.surname} {item.user.details.names} - {item.user.details.post})</span>}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                Whether Khuddam of Majlis offer services as
                                                security guard during Jamaat ceremonies like
                                                Friday Prayer, Id, Ijtema , other meetings
                                                etc.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Jummat</td>
                                            <td>{item.jummat ? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <td>Eid</td>
                                            <td>{item.eid ? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <td>Ijtema</td>
                                            <td>{item.ijtema ? "Yes" : "No"}</td>
                                        </tr>

                                        <tr>
                                            <td>Jalsa</td>
                                            <td>{item.jalsa ? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <td> Others</td>
                                            <td>{item.other ? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Was separate report of the department
                                                sent?
                                            </td>
                                            <td>{item.report_sent ? "Yes" : "No"}</td>
                                        </tr>
                                        <tr>
                                            <td>No of khuddam on special squad</td>
                                            <td>
                                                {item.special_squad}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> No of security training held</td>
                                            <td>{item.training}</td>
                                        </tr>
                                        <tr>
                                            <td>No of khuddam in attendance</td>
                                            <td>{item.attendance}</td>
                                        </tr>

                                        <tr>
                                            <td>No of khuddam who can drive</td>
                                            <td>{item.driver}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Is your Qaidat registered with the NYCN?
                                            </td>
                                            <td>{item.nycn1}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Attendance at regular NYCN meetings
                                            </td>
                                            <td>{item.nycn2}</td>
                                        </tr>


                                        <tr>
                                            <td>Month, Year</td>
                                            <td>
                                                {moment()
                                                    .month(item.month - 1)
                                                    .format("MMMM")}
                                                , {item.year}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Level</td>
                                            <td>{this.getLevel(item.report_level)}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Submission</td>
                                            <td>
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY, h:mm a"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}


                {office === "health" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>

                        <h2>Aggregate SEH'HAT -E- JISMANI Report for all {this.getLevel(level.toString())}</h2>
                        {this.aggregateData() && (<div>

                            <table className="table">
                                <thead className=" text-primary">
                                    <tr>
                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td>
                                            No that organized Sport Tournament organised this
                                            month?
                                        </td>
                                        <td>
                                            {this.aggregateData().sport_yes}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No that didnt organized Sport Tournament organised this
                                            month?
                                        </td>
                                        <td>
                                            {this.aggregateData().sport_no}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>No of such matches</td>
                                        <td>{this.aggregateData().matches}</td>
                                    </tr>
                                    <tr>
                                        <td>No of tournaments</td>
                                        <td>{this.aggregateData().matches2}</td>
                                    </tr>

                                    <tr>
                                        <td>List of individual tournaments</td>
                                        <td>{this.aggregateData().individual}</td>
                                    </tr>
                                    <tr>
                                        <td>No. of participants</td>
                                        <td>{this.aggregateData().participants}</td>
                                    </tr>
                                    <tr>
                                        <td>No of health talk</td>
                                        <td>{this.aggregateData().health_talk}</td>
                                    </tr>
                                    <tr>
                                        <td>No of screening</td>
                                        <td>{this.aggregateData().screening}</td>
                                    </tr>
                                    <tr>
                                        <td>No. of participating Khuddam</td>
                                        <td>{this.aggregateData().particip_k}</td>
                                    </tr>
                                    <tr>
                                        <td>No. of beneficiaries</td>
                                        <td>
                                            {this.aggregateData().beneficiaries}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        )
                        }

                        <h2>{this.getLevel(level.toString())} SEH'HAT -E- JISMANI
                            Report {month === 0 ? " All Months " : moment()
                                .month(month - 1)
                                .format("MMMM")} {year === 0 ? " All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>

                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <table className="table">
                                    <thead className=" text-primary">
                                        <tr>
                                            <th>Field</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Submitted By</td>
                                            <td>{item.submitted_by} {item.user &&
                                                <span>({item.user.details.surname} {item.user.details.names} - {item.user.details.post})</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Was any Sport Tournament organised this
                                                month?
                                            </td>
                                            <td>
                                                {item.sport ? "Yes" : "No"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>No of such matches</td>
                                            <td>{item.matches}</td>
                                        </tr>
                                        <tr>
                                            <td>No of tournaments</td>
                                            <td>{item.matches2}</td>
                                        </tr>

                                        <tr>
                                            <td>List of individual tournaments</td>
                                            <td>{item.individual}</td>
                                        </tr>
                                        <tr>
                                            <td>No. of participants</td>
                                            <td>{item.participants}</td>
                                        </tr>
                                        <tr>
                                            <td>No of health talk</td>
                                            <td>{item.health_talk}</td>
                                        </tr>
                                        <tr>
                                            <td>No of screening</td>
                                            <td>{item.screening}</td>
                                        </tr>
                                        <tr>
                                            <td>No. of participating Khuddam</td>
                                            <td>{item.particip_k}</td>
                                        </tr>
                                        <tr>
                                            <td>No. of beneficiaries</td>
                                            <td>
                                                {item.beneficiaries}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Month, Year</td>
                                            <td>
                                                {moment()
                                                    .month(item.month - 1)
                                                    .format("MMMM")}
                                                , {item.year}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Level</td>
                                            <td>{this.getLevel(item.report_level)}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Submission</td>
                                            <td>
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY, h:mm a"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}

                {office === "ishaat" && (





                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>

                        <h2>Aggregate ISHAAT Report for all {this.getLevel(level.toString())}</h2>
                        {this.aggregateData() && (
                            <table className="table">
                                <thead className=" text-primary">
                                    <tr>
                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>
                                            No. of Khuddam subscribers of the
                                            following periodicals / journals in your
                                            Majlis (The Truth)
                                        </td>
                                        <td>{this.aggregateData().truth}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No. of Khuddam subscribers of the
                                            following periodicals / journals in your
                                            Majlis (Review of Religion)
                                        </td>
                                        <td>{this.aggregateData().review}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            No. of Khuddam subscribers of the
                                            following periodicals / journals in your
                                            Majlis (Al Irfan)
                                        </td>
                                        <td>{this.aggregateData().irfan}</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            No. of Khuddam subscribers of the
                                            following periodicals / journals in your
                                            Majlis (Jamaat Websites)
                                        </td>
                                        <td>{this.aggregateData().websites}</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            No. of Khuddam who contributed during
                                            the months towards issuing Jamaat
                                            periodicals for Tabligh purpose
                                        </td>
                                        <td>{this.aggregateData().periodicals}</td>
                                    </tr>


                                </tbody>
                            </table>
                        )}
                        <h2>{this.getLevel(level.toString())} Ishaat Report {month === 0 ? "All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>

                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <table className="table">
                                    <thead className=" text-primary">
                                        <tr>
                                            <th>Field</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Submitted By</td>
                                            <td>{item.submitted_by} {item.user &&
                                                <span>({item.user.details.surname} {item.user.details.names} - {item.user.details.post})</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                No. of Khuddam subscribers of the
                                                following periodicals / journals in your
                                                Majlis (The Truth)
                                            </td>
                                            <td>{item.truth}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                No. of Khuddam subscribers of the
                                                following periodicals / journals in your
                                                Majlis (Review of Religion)
                                            </td>
                                            <td>{item.review}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                No. of Khuddam subscribers of the
                                                following periodicals / journals in your
                                                Majlis (Al Irfan)
                                            </td>
                                            <td>{item.irfan}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                No. of Khuddam subscribers of the
                                                following periodicals / journals in your
                                                Majlis (Jamaat Websites)
                                            </td>
                                            <td>{item.websites}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Any extra ordinary work in publication
                                                during the month
                                            </td>
                                            <td>{item.extra_work}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                No. of Khuddam who contributed during
                                                the months towards issuing Jamaat
                                                periodicals for Tabligh purpose
                                            </td>
                                            <td>{item.periodicals}</td>
                                        </tr>
                                        <tr>
                                            <td>News item about your Qaidaat</td>
                                            <td>{item.news_items}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Attachment (Copies of publications in
                                                the month)
                                            </td>
                                            <td>
                                                {!item.attachment || item.attachment === ""
                                                    ? "None"
                                                    : "Submitted"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Nazim Ishaat's Phone Number</td>
                                            <td>{item.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Month, Year</td>
                                            <td>
                                                {moment()
                                                    .month(item.month - 1)
                                                    .format("MMMM")}
                                                , {item.year}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Level</td>
                                            <td>{this.getLevel(item.report_level)}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Submission</td>
                                            <td>
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY, h:mm a"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}


                {office === "waqaramal" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>

                        <h2>Aggregate Waqaramal Report for all {this.getLevel(level.toString())}</h2>
                        {this.aggregateData() && (
                            <table className="table">
                                <thead className=" text-primary">
                                    <tr>
                                        <th>Field</th>
                                        <th>Response</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td>Number of Waqar-e-Amal Events</td>
                                        <td>{this.aggregateData().event}</td>
                                    </tr>
                                    <tr>
                                        <td>Type of events</td>
                                        <td>{this.aggregateData().type}</td>
                                    </tr>
                                    <tr>
                                        <td>Attendance</td>
                                        <td>{this.aggregateData().attendance}</td>
                                    </tr>



                                </tbody>
                            </table>
                        )
                        }
                        <h2>{this.getLevel(level.toString())} Waqaramal Report {month === 0 ? "All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <table className="table">
                                    <thead className=" text-primary">
                                        <tr>
                                            <th>Field</th>
                                            <th>Response</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>

                                            <td>Submitted By</td>
                                            <td>{item.submitted_by} {item.user &&
                                                <span>({item.user.details.surname} {item.user.details.names} - {item.user.details.post})</span>}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Waqar-e-Amal Events</td>
                                            <td>{item.event}</td>
                                        </tr>
                                        <tr>
                                            <td>Type of events</td>
                                            <td>{item.type}</td>
                                        </tr>
                                        <tr>
                                            <td>Attendance</td>
                                            <td>{item.attendance}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                External Waqar-e-Amal during the year
                                            </td>
                                            <td>{item.external}</td>
                                        </tr>
                                        <tr>
                                            <td>Attachment</td>
                                            <td>
                                                {!item.attachment || item.attachment === ""
                                                    ? "None"
                                                    : "Submitted"}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Nazim Waqar e Amal's phone number
                                            </td>
                                            <td>{item.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Month, Year</td>
                                            <td>
                                                {moment()
                                                    .month(item.month - 1)
                                                    .format("MMMM")}
                                                , {item.year}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Level</td>
                                            <td>{this.getLevel(item.report_level)}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Submission</td>
                                            <td>
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY, h:mm a"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}


                {office === "maal" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} Maal Report {month === 0 ? "All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <MaalTable data={item} />
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}

                {office === "new_convert" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} Nou - Mubaiyeen
                            Report {month === 0 ? "All Months " : moment()
                                .month(month - 1)
                                .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <NewConvert data={item} />
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}
                {office === "secretariat" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} A'ITMAD Report {month === 0 ? "All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <Secretariat data={item} />
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}
                {office === "social" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} KHIDMAT E KHALQ
                            Report {month === 0 ? "All Months " : moment()
                                .month(month - 1)
                                .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {/*{members.length > 0 &&*/}
                        {/*<TableBody>*/}
                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <Social data={item} />
                            </div>
                        ))}
                        {/*</TableBody>*/}
                        {/*}*/}
                    </div>
                )}
                {office === "student" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} UMOOR E TULABA
                            Report {month === 0 ? "All Months " : moment()
                                .month(month - 1)
                                .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <Student data={item} />
                            </div>
                        ))}
                    </div>
                )}
                {office === "tabligh" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} Tabligh Report {month === 0 ? "All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <Tabligh data={item} />
                            </div>
                        ))}
                    </div>
                )}

                {office === "tarbiyya" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} Tarbiyya Report {month === 0 ? "All Months " : moment()
                            .month(month - 1)
                            .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <Tarbiyya data={item} />
                            </div>
                        ))}
                    </div>
                )}

                {office === "trade" && (
                    <div style={{ whiteSpace: "pre", marginTop: 20 }}>
                        <h2>{this.getLevel(level.toString())} SANAT-O-TIJARAT
                            Report {month === 0 ? "All Months " : moment()
                                .month(month - 1)
                                .format("MMMM")} {year === 0 ? "All Years " : year}</h2>

                        {data.map((item, index) => (
                            <div key={index}>
                                {item.user &&
                                    <h3><strong>{this.getHeading(item.user.details, item.report_level)}</strong></h3>
                                }
                                <Trade data={item} />
                            </div>
                        ))}
                    </div>
                )}


            </div>
        );
    }
}

const RenderRow = (props) => {
    return props.keys.map((key, index) => {
        return <td key={props.data[key]}>{props.data[key]}</td>;
    });
};

const RenderRow2 = (props) => {
    return (
        <td key={props.data}>
            {props.data}: {props.items[props.data].toString()}
        </td>
    );
};

UserHome.propTypes = {
    createUser: PropTypes.func.isRequired,
    logOutUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    user: state.person.user,
    isLoggedIn: state.person.isLoggedIn,
});

export default connect(mapStateToProps, { createUser, logOutUser })(UserHome);
