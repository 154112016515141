import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class Social extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>
                        No. of Khuddam involved in Rishtanata
                        activities
                    </td>
                    <td>{data.rishtanata}</td>
                </tr>
                <tr>
                    <td>No. of widows</td>
                    <td>{data.widows}</td>
                </tr>
                <tr>
                    <td>
                        No. of unmarried khuddam (from ages 24)
                    </td>
                    <td>{data.unmarried}</td>
                </tr>

                <tr>
                    <td>First Aid</td>
                    <td>{data.first_aid}</td>
                </tr>
                <tr>
                    <td>Medical Camp</td>
                    <td>{data.medical_camp}</td>
                </tr>
                <tr>
                    <td>Blood donation</td>
                    <td>{data.b_donation}</td>
                </tr>
                <tr>
                    <td>Visit to elders</td>
                    <td>{data.elder}</td>
                </tr>
                <tr>
                    <td>Visit to Orphanage</td>
                    <td>{data.orphan}</td>
                </tr>
                <tr>
                    <td>Visit to clinics/hospitals</td>
                    <td> {data.clinics}</td>
                </tr>

                <tr>
                    <td>Visit to Khuddam</td>
                    <td>{data.khudam}</td>
                </tr>

                <tr>
                    <td>
                        How many meetings on importance of
                        Physical Hygiene
                    </td>
                    <td>{data.hygiene}</td>
                </tr>

                <tr>
                    <td>Aqiqah</td>
                    <td>{data.aqiqah}</td>
                </tr>

                <tr>
                    <td>Nikkah</td>
                    <td>{data.nikah}</td>
                </tr>

                <tr>
                    <td>Janaza</td>
                    <td>{data.janaza}</td>
                </tr>

                <tr>
                    <td>Walimot/Aminu Quran</td>
                    <td>{data.walimot}</td>
                </tr>

                <tr>
                    <td>House warming</td>
                    <td>{data.house}</td>
                </tr>

                <tr>
                    <td>Car warming</td>
                    <td>{data.car}</td>
                </tr>

                <tr>
                    <td>No of khuddam on duty</td>
                    <td>{data.on_duty}</td>
                </tr>

                <tr>
                    <td>
                        Nazim Khidmat Khalq's phone number
                    </td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default Social;
