import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class Tarbiyya extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>
                        No of Tarbiyyah Programs conducted
                    </td>
                    <td>
                        {
                            data
                                .no_tarbiyya_program
                        }
                    </td>
                </tr>
                <tr>
                    <td>Attendance</td>
                    <td>{data.attendance}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who observe
                        Congregrational Prayer in Mosque
                    </td>
                    <td>{data.prayer}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who write letter to huzur
                    </td>
                    <td>{data.letter}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who watch MTA reagularly
                    </td>
                    <td>{data.mta}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who are regular in
                        Tahajjud prayers
                    </td>
                    <td>{data.tahjud}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who are regular in 5 daily
                        prayers
                    </td>
                    <td>
                        {data.daily_prayer}
                    </td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who engaged in voluntary
                        fast
                    </td>
                    <td>{data.fast}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who read Condition of
                        Baiat
                    </td>
                    <td>{data.bait}</td>
                </tr>

                <tr>
                    <td>Nazim Tarbiyya's Phone Number</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default Tarbiyya;
