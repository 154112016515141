import { NEW_USER, LOGOUT_USER } from '../actions/types';

const initialState = {
    user: {},
    isLoggedIn: false
}

export default function(state = initialState, action) {
    switch (action.type) {
        case NEW_USER:
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true
            }
        case LOGOUT_USER:
            return {
                ...state,
                user: {},
                isLoggedIn: false
            }

        default:
            return state;
    }
}