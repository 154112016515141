import React from "react";

export const NetworkError = () => (
  <div className="text-center">
    <h1>No Internet Connection</h1>
    <button
      className="btn btn-primary"
      onClick={() => window.location.reload()}
    >
      <i className="fa fa-refresh"></i> Reload
    </button>
  </div>
);
