import { NEW_USER, LOGOUT_USER } from '../actions/types';

export const createUser = (userData) => dispatch =>{
   
       dispatch({
            type: NEW_USER,
            payload : userData


        });
}

export const logOutUser = () => dispatch =>{
   
    dispatch({
         type: LOGOUT_USER,
         payload : null


     });
}

