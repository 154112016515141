import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { logOutUser } from "../../actions/postAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class UserNav extends Component {
  constructor() {
    super();
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleLogout() {
    this.props.logOutUser();
    window.location.replace("/");
  }
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div className="container-fluid">
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <button
                id="minimizeSidebar"
                className="btn btn-just-icon btn-white btn-fab btn-round"
              >
                <i className="material-icons text_align-center visible-on-sidebar-regular">
                  more_vert
                </i>
                <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">
                  view_list
                </i>
              </button>
            </div>
            <a className="navbar-brand" href="#pablo">
              Monthly Report
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon icon-bar"></span>
            <span className="navbar-toggler-icon icon-bar"></span>
            <span className="navbar-toggler-icon icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end">
            {this.props.isLoggedIn ? this.props.user.names : ""} -
            {this.props.user.dila ? " " + this.props.user.dila + " dila" : ""}
            {this.props.user.state
              ? " " + this.props.user.state + " Ilaqa"
              : ""}
            {this.props.user.muqami
              ? " " + this.props.user.muqami + " muqami"
              : ""}
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="#pablo"
                  id="navbarDropdownProfile"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-icons">person</i>
                  <p className="d-lg-none d-md-block">Account</p>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdownProfile"
                >
                  <p className="dropdown-item" onClick={this.handleLogout}>
                    Log out
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      /* <Nav className="main-header navbar navbar-expand bg-white navbar-light border-bottom">
  
  <ul className="navbar-nav">
      <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#"><i className="fa fa-bars"></i></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
          <NavLink to="/" className="nav-link">Home</NavLink>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
     
      </li>
  </ul>




  <ul className="navbar-nav ml-auto">
    


      <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="/">
       

              <i className="nav-icon fa fa-sign-out text-danger"></i>
              <span className="text-dark">Log out</span>
          </a>

      </li>

  </ul>
</Nav> */
    );
  }
}

UserNav.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  user: state.person.user,
  isLoggedIn: state.person.isLoggedIn
});

export default connect(
  mapStateToProps,
  { logOutUser }
)(UserNav);
