import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink
} from "react-router-dom";
import Home from "../Home";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import { NoMatch } from "../NoMatch";
import UserSidebar from "./UserSidebar";
import UserNav from "./UserNav";
import { endpoints, http } from "../../configs/https";

import { logOutUser } from "../../actions/postAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NetworkError } from "../NetworkError";
import axios from "axios";
import { SITE_URL } from "../../constant";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      auth: true,
      networkError: false
    };
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      http
        .post(endpoints.VERIFY_AUTH, { token: this.props.user.token })
        .then(response => {
          // this.handleLoginSuccess(response);
          console.log(response);
        })
        .catch(error => {
          let errorMessage =
            (typeof error === "string" && error) ||
            "An error occured, please try again in a moment.";
          // alert(JSON.stringify(error));
          console.log(errorMessage);
        });

      //   axios
      //     .post(SITE_URL + "auth/verify/", { token: this.props.user.token })
      //     .then(res => {
      //       console.log(res);

      //       console.log(res.data);
      //       if (res.status === 200) {
      //         this.setState({
      //           auth: true
      //         });
      //       } else if (res.status === 403) {
      //         this.props.logOutUser();
      //         this.setState({
      //           auth: false
      //         });
      //       }
      //     })
      //     .catch(error => {
      //       this.setState({
      //         auth: false
      //       });

      //       if (error.message === "Network Error") {
      //         this.setState({
      //           networkError: true
      //         });
      //       } else {
      //         if (
      //           error.response.status === 401 ||
      //           error.response.status === 403
      //         ) {
      //           this.props.logOutUser();
      //           window.location.replace("/");
      //           this.setState({});
      //         } else {
      //         }
      //       }
      //     });
      // } else {
      //   this.setState({
      //     auth: false
      //   });
      //   //   window.location.replace("/");
    }
  }

  handleChange(event) {}
  render() {
    return (
      <div>
        {this.state.auth ? (
          <div>
            <div className="wrapper">
              <UserSidebar />

              <div className="main-panel">
                <UserNav />

                <div className="content">
                  <div className="content">
                    <div className="container-fluid">{this.props.children}</div>
                  </div>
                </div>

                <footer className="footer">
                  <div className="container-fluid">
                    <nav className="float-left">
                      &copy; {new Date().getFullYear()} All rights reserved.
                    </nav>
                    <div className="copyright float-right">
                      <b>Designed By</b> MKAN Nigeria.
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        ) : (
          <div>{this.state.networkError ? <NetworkError /> : <div></div>}</div>
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  user: state.person.user,
  isLoggedIn: state.person.isLoggedIn
});

export default connect(mapStateToProps, { logOutUser })(Dashboard);
