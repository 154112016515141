export const BASE_URL = "http://209.105.243.210:8800/api/v1/";
export const SITE_URL = "http://209.105.243.210:8800/";
// export const BASE_URL = "https://tajnid.herokuapp.com/api/v1/";
// export const SITE_URL = "https://tajnid.herokuapp.com/";


export const getLevel = function (level) {
    if (level === "1") {
        return "Ilaqa";
    } else if (level === "2") {
        return "Dila";
    } else if (level === "3") {
        return "Muqami";
    }
};


export function formatDateToString(d) {
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  }