import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class Student extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>
                        No of Meeting conducted this month
                        emphasising the importance and benefits
                        of education
                    </td>
                    <td>{data.meetings}</td>
                </tr>
                <tr>
                    <td>Attendance</td>
                    <td>{data.attendance}</td>
                </tr>
                <tr>
                    <td>
                        No of free coaching class organised
                    </td>
                    <td>{data.coaching}</td>
                </tr>
                <tr>
                    <td>
                        No of Career counselling / Planning
                        sessions in your Majlis to guide
                        students
                    </td>
                    <td>{data.career}</td>
                </tr>
                <tr>
                    <td>
                        No of Students provided counselling
                    </td>
                    <td> {data.student}</td>
                </tr>
                <tr>
                    <td>
                        No. of Career counselling guidance
                        articles published in the periodicals of
                        Jama’at by your Majlis
                    </td>
                    <td>{data.periodicals}</td>
                </tr>
                <tr>
                    <td>
                        Endeavours done to improve the religious
                        and moral standard of Khuddam, and to
                        consolidate their religions and
                        relationship with Jama’at
                    </td>
                    <td>
                        <p>{data.standard}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        Nazim Umoor Tulaba's phone number
                    </td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default Student;
