import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class Tabligh extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>
                        Media Distributed (Cassette, CD, DVD)
                    </td>
                    <td>{data.media}</td>
                </tr>
                <tr>
                    <td>Question and Answer Sessions</td>
                    <td>{data.question}</td>
                </tr>
                <tr>
                    <td>Public Lectures in the month</td>
                    <td>{data.public}</td>
                </tr>
                <tr>
                    <td>House to house preaching</td>
                    <td>{data.house}</td>
                </tr>
                <tr>
                    <td>Literature Books Distributed</td>
                    <td>{data.literature}</td>
                </tr>
                <tr>
                    <td>Tabligh Meetings</td>
                    <td>{data.tabligh}</td>
                </tr>
                <tr>
                    <td>Letters on Tabligh in the month</td>
                    <td>{data.letters}</td>
                </tr>
                <tr>
                    <td>Book Stall</td>
                    <td>{data.book}</td>
                </tr>
                <tr>
                    <td>Tabligh SMS/Emails</td>
                    <td>{data.tab_sms}</td>
                </tr>
                <tr>
                    <td>Number of Bai'ats</td>
                    <td>{data.n_baiat}</td>
                </tr>
                <tr>
                    <td>
                        No of Tabligh programmes organized in
                        the month
                    </td>
                    <td>{data.n_tabligh}</td>
                </tr>
                <tr>
                    <td> No participating Khuddam</td>
                    <td>{data.participants}</td>
                </tr>
                <tr>
                    <td>Duration</td>
                    <td>{data.duration}</td>
                </tr>
                <tr>
                    <td>
                        How many classes were conducted for the
                        tabligh team this month?
                    </td>
                    <td>{data.classes}</td>
                </tr>
                <tr>
                    <td>Attendance</td>
                    <td>{data.attendance}</td>
                </tr>

                <tr>
                    <td>Nazim Tabligh's Phone Number</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default Tabligh;
