import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class Trade extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>No. of unemployed Khuddam</td>
                    <td>{data.employed}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam provided employment during
                        the month
                    </td>
                    <td>
                        {data.employed_during}
                    </td>
                </tr>
                <tr>
                    <td>
                        Attach list of the newly employed to
                        National office
                    </td>
                    <td>
                        {!data.attachment1 || data.attachment1 === ""
                            ? "None"
                            : "Submitted"}
                    </td>
                </tr>

                <tr>
                    <td>No. of unskilled Khuddam</td>
                    <td>{data.unskilled}</td>
                </tr>
                <tr>
                    <td>
                        No. Khuddam being trained in a
                        particular skill
                    </td>
                    <td>{data.training}</td>
                </tr>
                <tr>
                    <td>
                        Attach list of khuddam trained in
                        particular skill
                    </td>
                    <td>
                        {!data.attachment2 ||data.attachment2 === ""
                            ? "None"
                            : "Submitted"}
                    </td>
                </tr>
                <tr>
                    <td>
                        Professions / skills in which khuddam
                        are experts
                    </td>
                    <td>{data.professions}</td>
                </tr>
                <tr>
                    <td>
                        Attach full details of profession /
                        skill which khuddam are expert
                    </td>
                    <td>
                        {!data.attachment3 || data.attachment3 === ""
                            ? "None"
                            : "Submitted"}
                    </td>
                </tr>
                <tr>
                    <td>No. of motivational talk</td>
                    <td>{data.motivation}</td>
                </tr>
                <tr>
                    <td>No. of Participant</td>
                    <td>{data.participants}</td>
                </tr>

                <tr>
                    <td>Nazim Sanat-o-Tijarat's Phone</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default Trade;
