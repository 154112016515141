import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUser, logOutUser } from "../actions/postAction";

import axios from "axios";
import { BASE_URL } from "../constant";
import Swal from "sweetalert2";
import { NetworkError } from "./NetworkError";

import { endpoints, http } from "../configs/https";
import Moment from "react-moment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme } from "@material-ui/core";

import moment from "moment";
import "moment-timezone";
import {
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    TablePagination,
} from "@material-ui/core";
import {Loading} from "./Loading";
import MaalTable from "./officeTable/MaalTable";
import NewConvert from "./officeTable/NewConvert";
import Secretariat from "./officeTable/Secretariat";
import Social from "./officeTable/Social";
import Student from "./officeTable/Student";
import Tabligh from "./officeTable/Tabligh";
import Tarbiyya from "./officeTable/Tarbiyya";
import Trade from "./officeTable/Trade";
import Taleem from "./officeTable/Taleem";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#229858",
            light: "#229858",
            dark: "#229858",
        },
        secondary: {
            main: "#229858",
        },
    },
});

class ViewReport extends Component {
    constructor() {
        super();
        this.state = {

            loading: false,
            networkError: false,

        };
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
    }

    handleChangeEvent(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {

        document.title = "Dashboard - MKAN REPORT";
        // const {page,rowsPerPage} = this.state;
         this.fetchQuery();
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }


    // componentDidMount() {
    //   const {page,rowsPerPage} = this.state;
    //   this.fetchMembers(page, rowsPerPage);
    // }

    fetchQuery() {
       // const urlParams = new URLSearchParams(window.location.search);
        // const office = urlParams.get('office');
        // const month = urlParams.get('month');
        // const year = urlParams.get('year');
        // const level = urlParams.get('level');
        // const id = urlParams.get('id');

        //const urlParams = new URLSearchParams(window.location.search);
        const office = this.props.office;
        const month = this.props.month;
        const year = this.props.year;
        const level = this.props.level;
        const id = this.props.post_id;
        console.log(office)
        console.log(month)
        console.log(year)


        this.setState({
            loading: true,
            showFilter: false,
            data: null,
            networkError: false,
        });


        let queryString = "";

        if (month) {
            queryString += "&month=" + month;
        }

        console.log(year);
        if (year) {
            queryString += "&year=" + year;
        }

        if (level) {
            queryString += "&report_level=" + level;
        }
        if (id) {
            queryString += "&post_id=" + id;
        }

        http
            .get(
                endpoints.QUERY_REPORT.replace(
                    ":office",
                    office
                ) + queryString
            )
            .then((response) => {
                console.log("hello");
                console.log(response.data);
                console.log(response.data[0]);
                this.setState({
                    loading: false,
                    data: response.data[0],
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    networkError: true,
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage,
                });
            });
    }


    getLevel = function (level) {
        if (level.toString() === "1") {
            return "Ilaqa";
        } else if (level.toString() === "2") {
            return "Dila";
        } else if (level.toString() === "3") {
            return "Muqami";
        }
    };

    applyFilter() {
        this.fetchQuery(1, this.state.rowsPerPage);
        this.setState({
            page: 1,
        });
    }


    render() {
        const {
            data,
            loading


        } = this.state;

        // const urlParams = new URLSearchParams(window.location.search);
        // const office = urlParams.get('office');
        // const month = urlParams.get('month');
        // const year = urlParams.get('year');
        // const level = urlParams.get('level');
        // const id = urlParams.get('id');

        const office = this.props.office;
        const month = this.props.month;
        const year = this.props.year;
        const level = this.props.level;
        const id = this.props.id;
        const name = this.props.name;

        return (
            <MuiThemeProvider theme={customTheme}>
                <div>
                    {loading &&
                    <div className="text-center container">
                        <center>
                            <Loading />
                        </center>
                    </div>
                    }
                    <div>
                        <button
                            onClick={() =>
                                this.props.close()
                            }
                            className="btn btn-success mt-5"
                        >
                            Go back
                        </button>

                        {data && (
                            <div>
                                <div className="w-100 overflow-auto">
                                    {office === "tajnid" && (
                                        <Table style={{ whiteSpace: "pre", marginTop: 20 }}>
                                            <h2>{name} {this.getLevel(level)} Tajneed Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                                <table className="table" >
                                                    <thead className=" text-primary">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Field</th>
                                                        <th>Response</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Submitted By</td>
                                                        <td>{data.submitted_by}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Total Number of Khuddam</td>
                                                        <td>{data.total_khudam}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Total Number of Khuddam at 40</td>
                                                        <td>{data.khudam_at_40}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Total Number of Khuddam with Uniform</td>
                                                        <td>{data.total_with_uniform}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Total Number of Atfal</td>
                                                        <td>{data.total_atfal}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Nazim Tajneed's Phone Number</td>
                                                        <td>{data.phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>Month, Year</td>
                                                        <td>
                                                            {moment()
                                                                .month(data.month - 1)
                                                                .format("MMMM")}
                                                            , {data.year}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>Level</td>
                                                        <td>{this.getLevel(data.report_level)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>Date of Submission</td>
                                                        <td>
                                                            {moment(data.date).format(
                                                                "MMMM Do YYYY, h:mm a"
                                                            )}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                        </Table>
                                    )}

                                    {office === "taleem" && (
                                        <Table style={{ whiteSpace: "pre", marginTop: 20 }}>
                                            <h2>{name} {this.getLevel(level)} Taleem Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>


                                                <Taleem data={data}/>

                                        </Table>
                                    )}

                                    {office === "atfal" && (
                                        <Table style={{ whiteSpace: "pre", marginTop: 20 }}>
                                            <h2>{name} {this.getLevel(level)} Atfal Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>

                                            <table className="table">
                                                <thead className=" text-primary">
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Field</th>
                                                    <th>Response</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Submitted By</td>
                                                    <td>{data.submitted_by}</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td> No. of Atfals</td>
                                                    <td>{data.atfal}</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        {" "}
                                                        No. of Atfal sessions held in the month
                                                    </td>
                                                    <td>{data.session}</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>
                                                        {" "}
                                                        Is Atfal organisation duly established?
                                                    </td>
                                                    <td>
                                                        {data.organised
                                                            ? "Yes"
                                                            : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>
                                                        If Atfal organisation is not established
                                                        , state reasons thereof and also mention
                                                        what efforts have been done in this
                                                        regard
                                                    </td>
                                                    <td>{data.establish}</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>No. of Organized Camp</td>
                                                    <td>{data.no_camp}</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>No. of Atfal Present</td>
                                                    <td>{data.no_atfal}</td>
                                                </tr>

                                                <tr>
                                                    <td>8</td>
                                                    <td>No. of Khuddam Present</td>
                                                    <td>{data.no_khudam}</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>Attachment (Atfal Report)</td>
                                                    <td>
                                                        {!data.attachment || data.attachment === ""
                                                            ? "None"
                                                            : "Submitted"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td> Nazim Atfal's phone number</td>
                                                    <td>{data.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td>Month, Year</td>
                                                    <td>
                                                        {" "}
                                                        {moment()
                                                            .month(data.month - 1)
                                                            .format("MMMM")}
                                                        , {data.year}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td>Level</td>
                                                    <td> {this.getLevel(data.report_level)}</td>
                                                </tr>
                                                <tr>
                                                    <td>13</td>
                                                    <td>Date of Submission</td>
                                                    <td>
                                                        {moment(data.date).format(
                                                            "MMMM Do YYYY, h:mm a"
                                                        )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {/*</TableBody>*/}
                                            {/*}*/}
                                        </Table>
                                    )}
                                    {office === "trade" && (
                                        <Table style={{ whiteSpace: "pre", marginTop: 20 }}>
                                            <h2>{name} {this.getLevel(level)} SANAT-O-TIJARAT Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <Trade data={data}/>
                                            {/*</TableBody>*/}
                                            {/*}*/}
                                        </Table>
                                    )}


                                    {/*{office === "secretariat" && (*/}
                                    {/*    <Table style={{ whiteSpace: "pre", marginTop: 20 }}>*/}
                                    {/*        <h2>{name} {this.getLevel(level)} A'ITMAD Report for {moment()*/}
                                    {/*            .month(month - 1)*/}
                                    {/*            .format("MMMM")}*/}
                                    {/*            , {year}</h2>*/}

                                    {/*        <table className="table">*/}
                                    {/*            <thead className=" text-primary">*/}
                                    {/*            <tr>*/}
                                    {/*                <th>ID</th>*/}
                                    {/*                <th>Field</th>*/}
                                    {/*                <th>Response</th>*/}
                                    {/*            </tr>*/}
                                    {/*            </thead>*/}

                                    {/*            <tbody>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>1</td>*/}
                                    {/*                <td>Submitted By</td>*/}
                                    {/*                <td>{data.submitted_by}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>2</td>*/}
                                    {/*                <td>*/}
                                    {/*                    Were the Executive Committee Meetings*/}
                                    {/*                    conducted during the month?*/}
                                    {/*                </td>*/}
                                    {/*                <td>*/}
                                    {/*                    {data.exec_meeting*/}
                                    {/*                        ? "Yes"*/}
                                    {/*                        : "No"}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>3</td>*/}
                                    {/*                <td>How many?</td>*/}
                                    {/*                <td>{data.freq}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>4</td>*/}
                                    {/*                <td>*/}
                                    {/*                    Total Number of Office–bearer Present*/}
                                    {/*                </td>*/}
                                    {/*                <td>{data.office_bearer}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>5</td>*/}
                                    {/*                <td>*/}
                                    {/*                    Whether previous report for last month*/}
                                    {/*                    was submitted*/}
                                    {/*                </td>*/}
                                    {/*                <td>*/}
                                    {/*                    {data.submission*/}
                                    {/*                        ? "Yes"*/}
                                    {/*                        : "No"}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>6</td>*/}
                                    {/*                <td>Do you have Qaida'at Secretary?</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {" "}*/}
                                    {/*                    {data.qaidat_sec*/}
                                    {/*                        ? "Yes"*/}
                                    {/*                        : "No"}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>7</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {" "}*/}
                                    {/*                    Number of Mulk Members in the Qaidat*/}
                                    {/*                </td>*/}
                                    {/*                <td>{data.mulk_total}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>8</td>*/}
                                    {/*                <td> No Present at meeting</td>*/}
                                    {/*                <td>{data.mulk_present}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>9</td>*/}
                                    {/*                <td> No Absent</td>*/}
                                    {/*                <td>{data.mulk_absent}</td>*/}
                                    {/*            </tr>*/}

                                    {/*            <tr>*/}
                                    {/*                <td>10</td>*/}
                                    {/*                <td>Motamad's Phone Number</td>*/}
                                    {/*                <td>{data.phone}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>11</td>*/}
                                    {/*                <td>Month, Year</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {" "}*/}
                                    {/*                    {moment()*/}
                                    {/*                        .month(data.month - 1)*/}
                                    {/*                        .format("MMMM")}*/}
                                    {/*                    , {data.year}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>12</td>*/}
                                    {/*                <td>Level</td>*/}
                                    {/*                <td> {this.getLevel(data.report_level)}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>13</td>*/}
                                    {/*                <td>Date of Submission</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {moment(data.date).format(*/}
                                    {/*                        "MMMM Do YYYY, h:mm a"*/}
                                    {/*                    )}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            </tbody>*/}
                                    {/*        </table>*/}
                                    {/*        /!*</TableBody>*!/*/}
                                    {/*        /!*}*!/*/}
                                    {/*    </Table>*/}
                                    {/*)}*/}

                                    {/*{office === "tabligh" && (*/}
                                    {/*    <table className="table">*/}
                                    {/*        <thead className=" text-primary">*/}
                                    {/*        <tr>*/}
                                    {/*            <th>ID</th>*/}
                                    {/*            <th>Field</th>*/}
                                    {/*            <th>Response</th>*/}
                                    {/*        </tr>*/}
                                    {/*        </thead>*/}
                                    {/*        <tbody>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>1</td>*/}
                                    {/*            <td>Submitted By</td>*/}
                                    {/*            <td>{data.submitted_by}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>2</td>*/}
                                    {/*            <td>*/}
                                    {/*                Media Distributed (Cassette, CD, DVD)*/}
                                    {/*            </td>*/}
                                    {/*            <td>{data.media}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>3</td>*/}
                                    {/*            <td> Question and Answer Sessions</td>*/}
                                    {/*            <td>{data.question}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>4</td>*/}
                                    {/*            <td> Public Lectures in the month</td>*/}
                                    {/*            <td>{data.public}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>5</td>*/}
                                    {/*            <td>House to house preaching</td>*/}
                                    {/*            <td>{data.house}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>6</td>*/}
                                    {/*            <td> Literature Books Distributed</td>*/}
                                    {/*            <td>{data.literature}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>7</td>*/}
                                    {/*            <td>Tabligh Meetings</td>*/}
                                    {/*            <td>{data.tabligh}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>8</td>*/}
                                    {/*            <td>Letters on Tabligh in the month</td>*/}
                                    {/*            <td>{data.letters}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>9</td>*/}
                                    {/*            <td> Book Stall</td>*/}
                                    {/*            <td>{data.book}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>10</td>*/}
                                    {/*            <td> Tabligh SMS/Emails</td>*/}
                                    {/*            <td>{data.tab_sms}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>11</td>*/}
                                    {/*            <td> Number of Bai'ats</td>*/}
                                    {/*            <td>{data.n_baiat}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>12</td>*/}
                                    {/*            <td>*/}
                                    {/*                No of Tabligh programmes organized in*/}
                                    {/*                the month*/}
                                    {/*            </td>*/}
                                    {/*            <td>{data.n_tabligh}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>13</td>*/}
                                    {/*            <td> No participating Khuddam</td>*/}
                                    {/*            <td>{data.participants}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>14</td>*/}
                                    {/*            <td> Duration</td>*/}
                                    {/*            <td>{data.duration}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>15</td>*/}
                                    {/*            <td>*/}
                                    {/*                How many classes were conducted for the*/}
                                    {/*                tabligh team this month?*/}
                                    {/*            </td>*/}
                                    {/*            <td>{data.classes}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>16</td>*/}
                                    {/*            <td> Attendance</td>*/}
                                    {/*            <td>{data.attendance}</td>*/}
                                    {/*        </tr>*/}

                                    {/*        <tr>*/}
                                    {/*            <td>17</td>*/}
                                    {/*            <td>Nazim Tabligh's Phone Number</td>*/}
                                    {/*            <td>{data.phone}</td>*/}
                                    {/*        </tr>*/}

                                    {/*        <tr>*/}
                                    {/*            <td>18</td>*/}
                                    {/*            <td>Month, Year</td>*/}
                                    {/*            <td>*/}
                                    {/*                {" "}*/}
                                    {/*                {moment()*/}
                                    {/*                    .month(data.month - 1)*/}
                                    {/*                    .format("MMMM")}*/}
                                    {/*                , {data.year}*/}
                                    {/*            </td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>19</td>*/}
                                    {/*            <td>Level</td>*/}
                                    {/*            <td>{this.getLevel(data.report_level)}</td>*/}
                                    {/*        </tr>*/}
                                    {/*        <tr>*/}
                                    {/*            <td>20</td>*/}
                                    {/*            <td>Date of Submission</td>*/}
                                    {/*            <td>*/}
                                    {/*                {moment(data.date).format(*/}
                                    {/*                    "MMMM Do YYYY, h:mm a"*/}
                                    {/*                )}*/}
                                    {/*            </td>*/}
                                    {/*        </tr>*/}
                                    {/*        </tbody>*/}
                                    {/*    </table>*/}
                                    {/*)}*/}

                                    {/*{office === "secretariat" && (*/}
                                    {/*    <Table style={{ whiteSpace: "pre", marginTop: 0 }}>*/}
                                    {/*        <h2>{name} {this.getLevel(level)} A'ITMAD Report for {moment()*/}
                                    {/*            .month(month - 1)*/}
                                    {/*            .format("MMMM")}*/}
                                    {/*            , {year}</h2>*/}

                                    {/*        <table className="table">*/}
                                    {/*            <thead className=" text-primary">*/}
                                    {/*            <tr>*/}
                                    {/*                <th>ID</th>*/}
                                    {/*                <th>Field</th>*/}
                                    {/*                <th>Response</th>*/}
                                    {/*            </tr>*/}
                                    {/*            </thead>*/}

                                    {/*            <tbody>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>1</td>*/}
                                    {/*                <td>Submitted By</td>*/}
                                    {/*                <td>{data.submitted_by}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>2</td>*/}
                                    {/*                <td>*/}
                                    {/*                    Were the Executive Committee Meetings*/}
                                    {/*                    conducted during the month?*/}
                                    {/*                </td>*/}
                                    {/*                <td>*/}
                                    {/*                    {data.exec_meeting*/}
                                    {/*                        ? "Yes"*/}
                                    {/*                        : "No"}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>3</td>*/}
                                    {/*                <td>How many?</td>*/}
                                    {/*                <td>{data.freq}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>4</td>*/}
                                    {/*                <td>*/}
                                    {/*                    Total Number of Office–bearer Present*/}
                                    {/*                </td>*/}
                                    {/*                <td>{data.office_bearer}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>5</td>*/}
                                    {/*                <td>*/}
                                    {/*                    Whether previous report for last month*/}
                                    {/*                    was submitted*/}
                                    {/*                </td>*/}
                                    {/*                <td>*/}
                                    {/*                    {data.submission*/}
                                    {/*                        ? "Yes"*/}
                                    {/*                        : "No"}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>6</td>*/}
                                    {/*                <td>Do you have Qaida'at Secretary?</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {" "}*/}
                                    {/*                    {data.qaidat_sec*/}
                                    {/*                        ? "Yes"*/}
                                    {/*                        : "No"}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>7</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {" "}*/}
                                    {/*                    Number of Mulk Members in the Qaidat*/}
                                    {/*                </td>*/}
                                    {/*                <td>{data.mulk_total}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>8</td>*/}
                                    {/*                <td> No Present at meeting</td>*/}
                                    {/*                <td>{data.mulk_present}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>9</td>*/}
                                    {/*                <td> No Absent</td>*/}
                                    {/*                <td>{data.mulk_absent}</td>*/}
                                    {/*            </tr>*/}

                                    {/*            <tr>*/}
                                    {/*                <td>10</td>*/}
                                    {/*                <td>Motamad's Phone Number</td>*/}
                                    {/*                <td>{data.phone}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>11</td>*/}
                                    {/*                <td>Month, Year</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {" "}*/}
                                    {/*                    {moment()*/}
                                    {/*                        .month(data.month - 1)*/}
                                    {/*                        .format("MMMM")}*/}
                                    {/*                    , {data.year}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>12</td>*/}
                                    {/*                <td>Level</td>*/}
                                    {/*                <td> {this.getLevel(data.report_level)}</td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td>13</td>*/}
                                    {/*                <td>Date of Submission</td>*/}
                                    {/*                <td>*/}
                                    {/*                    {moment(data.date).format(*/}
                                    {/*                        "MMMM Do YYYY, h:mm a"*/}
                                    {/*                    )}*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            </tbody>*/}
                                    {/*        </table>*/}
                                    {/*        /!*</TableBody>*!/*/}
                                    {/*        /!*}*!/*/}
                                    {/*    </Table>*/}
                                    {/*)}*/}

                                    {office === "tabligh" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} TABLIGH Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <Tabligh data={data}/>

                                        </div>
                                    )}
                                    {office === "secretariat" && (
                                        <Table style={{ whiteSpace: "pre", marginTop: 20 }}>
                                            <h2>{name} {this.getLevel(level)} A'ITMAD Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>

                                            <Secretariat data={data}/>
                                        </Table>
                                    )}

                                    {office === "tarbiyya" && (
                                        <Table style={{ whiteSpace: "pre", marginTop: 20 }}>
                                            <h2>{name} {this.getLevel(level)} TARBIYYA Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>

                                            <Tarbiyya data={data}/>
                                        </Table>
                                    )}

                                    {office === "audit" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} MUHASB Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                        <table className="table">

                                            <thead className=" text-primary">
                                            <tr>
                                                <th>ID</th>
                                                <th>Field</th>
                                                <th>Response</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Submitted By</td>
                                                <td>{data.submitted_by}</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    {" "}
                                                    Was income and expenditure accounts
                                                    audited this month?
                                                </td>
                                                <td>
                                                    {data.income ? "Yes" : "No"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    {" "}
                                                    Was a copy of the Quarterly Audit Report
                                                    sent to National Office?
                                                </td>
                                                <td>
                                                    {data.quarterly
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>
                                                    {" "}
                                                    Was a Day-book and Ledgers prescribed by
                                                    National Office being maintained in your
                                                    majlis?
                                                </td>
                                                <td>
                                                    {data.ledgers
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>5</td>
                                                <td> Nazim Muhasib's phone number</td>
                                                <td> {data.phone}</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Month, Year</td>
                                                <td>
                                                    {" "}
                                                    {moment()
                                                        .month(data.month - 1)
                                                        .format("MMMM")}
                                                    , {data.year}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Level</td>
                                                <td> {this.getLevel(data.report_level)}</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Date of Submission</td>
                                                <td>
                                                    {moment(data.date).format(
                                                        "MMMM Do YYYY, h:mm a"
                                                    )}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    )}


                                    {office === "general" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} UMOOMI Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <table className="table">
                                                <thead className=" text-primary">
                                                <tr>
                                                    <th>Field</th>
                                                    <th>Response</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td>Submitted By</td>
                                                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        Whether Khuddam of Majlis offer services as
                                                        security guard during Jamaat ceremonies like
                                                        Friday Prayer, Id, Ijtema , other meetings
                                                        etc.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Jummat</td>
                                                    <td>{data.jummat ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Eid</td>
                                                    <td>{data.eid ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Ijtema</td>
                                                    <td>{data.ijtema  ? "Yes" : "No"}</td>
                                                </tr>

                                                <tr>
                                                    <td>Jalsa</td>
                                                    <td>{data.jalsa  ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Others</td>
                                                    <td>{data.other  ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Was separate report of the department
                                                        sent?
                                                    </td>
                                                    <td>{data.report_sent  ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td>No of khuddam on special squad</td>
                                                    <td>
                                                        {data.special_squad}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td> No of security training held</td>
                                                    <td>{data.training}</td>
                                                </tr>
                                                <tr>
                                                    <td>No of khuddam in attendance</td>
                                                    <td>{data.attendance}</td>
                                                </tr>

                                                <tr>
                                                    <td>No of khuddam who can drive</td>
                                                    <td>{data.driver}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        Is your Qaidat registered with the NYCN?
                                                    </td>
                                                    <td>{data.nycn1}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        Attendance at regular NYCN meetings
                                                    </td>
                                                    <td>{data.nycn2}</td>
                                                </tr>

                                                <tr>
                                                    <td>Month, Year</td>
                                                    <td>
                                                        {moment()
                                                            .month(data.month - 1)
                                                            .format("MMMM")}
                                                        , {data.year}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Level</td>
                                                    <td>{this.getLevel(data.report_level)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Submission</td>
                                                    <td>
                                                        {moment(data.date).format(
                                                            "MMMM Do YYYY, h:mm a"
                                                        )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                    {office === "health" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} SEH'HAT -E- JISMANI Report Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <table className="table">
                                                <thead className=" text-primary">
                                                <tr>
                                                    <th>Field</th>
                                                    <th>Response</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td>Submitted By</td>
                                                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Was any Sport Tournament organised this
                                                        month?
                                                    </td>
                                                    <td>
                                                        {data.sport ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>No of such matches</td>
                                                    <td>{data.matches}</td>
                                                </tr>
                                                <tr>
                                                    <td>No of tournaments</td>
                                                    <td>{data.matches2}</td>
                                                </tr>

                                                <tr>
                                                    <td>List of individual tournaments</td>
                                                    <td>{data.individual}</td>
                                                </tr>
                                                <tr>
                                                    <td>No. of participants</td>
                                                    <td>{data.participants}</td>
                                                </tr>
                                                <tr>
                                                    <td>No of health talk </td>
                                                    <td>{data.health_talk}</td>
                                                </tr>
                                                <tr>
                                                    <td>No of screening </td>
                                                    <td>{data.screening}</td>
                                                </tr>
                                                <tr>
                                                    <td>No. of participating Khuddam</td>
                                                    <td>{data.particip_k}</td>
                                                </tr>
                                                <tr>
                                                    <td>No. of beneficiaries</td>
                                                    <td>
                                                        {data.beneficiaries}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Month, Year</td>
                                                    <td>
                                                        {moment()
                                                            .month(data.month - 1)
                                                            .format("MMMM")}
                                                        , {data.year}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Level</td>
                                                    <td>{this.getLevel(data.report_level)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Submission</td>
                                                    <td>
                                                        {moment(data.date).format(
                                                            "MMMM Do YYYY, h:mm a"
                                                        )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                    {office === "ishaat" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} ISHAAT Report Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <table className="table">
                                                <thead className=" text-primary">
                                                <tr>
                                                    <th>Field</th>
                                                    <th>Response</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td>Submitted By</td>
                                                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        No. of Khuddam subscribers of the
                                                        following periodicals / journals in your
                                                        Majlis (The Truth)
                                                    </td>
                                                    <td>{data.truth}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        No. of Khuddam subscribers of the
                                                        following periodicals / journals in your
                                                        Majlis (Review of Religion)
                                                    </td>
                                                    <td>{data.review}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        No. of Khuddam subscribers of the
                                                        following periodicals / journals in your
                                                        Majlis (Al Irfan)
                                                    </td>
                                                    <td>{data.irfan}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        No. of Khuddam subscribers of the
                                                        following periodicals / journals in your
                                                        Majlis (Jamaat Websites)
                                                    </td>
                                                    <td>{data.websites}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Any extra ordinary work in publication
                                                        during the month
                                                    </td>
                                                    <td>{data.extra_work}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        No. of Khuddam who contributed during
                                                        the months towards issuing Jamaat
                                                        periodicals for Tabligh purpose
                                                    </td>
                                                    <td>{data.periodicals}</td>
                                                </tr>
                                                <tr>
                                                    <td>News item about your Qaidaat</td>
                                                    <td>{data.news_items}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Attachment (Copies of publications in
                                                        the month)
                                                    </td>
                                                    <td>
                                                        {!data.attachment || data.attachment === ""
                                                            ? "None"
                                                            : "Submitted"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Nazim Ishaat's Phone Number</td>
                                                    <td>{data.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>Month, Year</td>
                                                    <td>
                                                        {moment()
                                                            .month(data.month - 1)
                                                            .format("MMMM")}
                                                        , {data.year}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Level</td>
                                                    <td>{this.getLevel(data.report_level)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Submission</td>
                                                    <td>
                                                        {moment(data.date).format(
                                                            "MMMM Do YYYY, h:mm a"
                                                        )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}


                                    {office === "waqaramal" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} WAQARAMAL Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <table className="table">
                                                <thead className=" text-primary">
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Field</th>
                                                    <th>Response</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Submitted By</td>
                                                    <td>{data.submitted_by}</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Number of Waqar-e-Amal Events</td>
                                                    <td>{data.event}</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Type of events</td>
                                                    <td>{data.type}</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Attendance</td>
                                                    <td>{data.attendance}</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>
                                                        External Waqar-e-Amal during the year
                                                    </td>
                                                    <td>{data.external}</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Attachment</td>
                                                    <td>
                                                        {" "}
                                                        {!data.attachment  || data.attachment === ""
                                                            ? "None"
                                                            : "Submitted"}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>7</td>
                                                    <td>
                                                        {" "}
                                                        Nazim Waqar e Amal's phone number
                                                    </td>
                                                    <td>{data.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>Month, Year</td>
                                                    <td>
                                                        {" "}
                                                        {moment()
                                                            .month(data.month - 1)
                                                            .format("MMMM")}
                                                        , {data.year}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>Level</td>
                                                    <td> {this.getLevel(data.report_level)}</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>Date of Submission</td>
                                                    <td>
                                                        {moment(data.date).format(
                                                            "MMMM Do YYYY, h:mm a"
                                                        )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                    {office === "maal" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} MAAL Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <MaalTable data={data}/>
                                        </div>
                                    )}

                                    {office === "new_convert" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} NOU - MUBAIYEEN Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <NewConvert data={data}/>
                                        </div>
                                    )}

                                    {office === "social" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} KHIDMAT E KHALQ Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <Social data={data}/>
                                        </div>
                                    )}

                                    {office === "student" && (
                                        <div>
                                            <h2>{name} {this.getLevel(level)} UMOOR E TULABA Report for {moment()
                                                .month(month - 1)
                                                .format("MMMM")}
                                                , {year}</h2>
                                            <Student data={data}/>
                                        </div>
                                    )}








                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </MuiThemeProvider>
            // <div>
            //   <form onSubmit={this.handleSubmit}>
            //     // try this also https://jsonplaceholder.typicode.com/posts/1/comments
            //     also try <br />
            //     // try this url also http://209.105.243.210:8800/api/v1/qaid/
            //     <input
            //       placeholder="Enter dynamic api to fetch from"
            //       className="form-control"
            //       name="url"
            //       value={this.state.url}
            //       onChange={this.handleChange}
            //     />
            //     <button className="btn btn-success" type="submit">
            //       Get
            //     </button>
            //   </form>
            //   <table className="table">
            //     <thead>
            //       <tr>{this.getHeader()}</tr>
            //     </thead>
            //     <tbody>{this.getRowsData()}</tbody>
            //   </table>
            //   <h2>Post full details</h2>
            //   // try this url also http://209.105.243.210:8800/api/v1/status
            //   <form onSubmit={this.handleSubmit2}>
            //     <input
            //       placeholder="Enter dynamic api to fetch from"
            //       className="form-control"
            //       name="url2"
            //       value={this.state.url2}
            //       onChange={this.handleChange}
            //     />
            //     <button className="btn btn-success" type="submit">
            //       Get
            //     </button>
            //   </form>
            //   <table className="table">
            //     <tbody>{this.getRowsData2()}</tbody>
            //   </table>
            // </div>
        );
    }
}



ViewReport.propTypes = {
    office: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    post_id: PropTypes.string.isRequired,

};


export default ViewReport;
