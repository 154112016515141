import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class Secretariat extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>
                        Were the Executive Committee Meetings
                        conducted during the month?
                    </td>
                    <td>
                        {data.exec_meeting
                            ? "Yes"
                            : "No"}
                    </td>
                </tr>
                <tr>
                    <td>How many?</td>
                    <td>{data.freq}</td>
                </tr>
                <tr>
                    <td>
                        Total Number of Office–bearer Present
                    </td>
                    <td>{data.office_bearer}</td>
                </tr>
                <tr>
                    <td>
                        Whether previous report for last month
                        was submitted
                    </td>
                    <td>
                        {data.submission
                            ? "Yes"
                            : "No"}
                    </td>
                </tr>
                <tr>
                    <td>Do you have Qaida'at Secretary?</td>
                    <td>
                        {data.qaidat_sec
                            ? "Yes"
                            : "No"}
                    </td>
                </tr>
                <tr>
                    <td>
                        Number of Mulk Members in the Qaidat
                    </td>
                    <td>{data.mulk_total}</td>
                </tr>
                <tr>
                    <td>No Present at meeting</td>
                    <td>{data.mulk_present}</td>
                </tr>
                <tr>
                    <td>No Absent</td>
                    <td>{data.mulk_absent}</td>
                </tr>

                <tr>
                    <td>Motamad's Phone Number</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default Secretariat;
