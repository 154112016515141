import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink
} from "react-router-dom";
import Home from "./components/Home";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import NoMatch from "./components/NoMatch";
import Default from "./components/layouts/Default";
import Dashboard from "./components/layouts/Dashboard";
import UserHome from "./components/UserHome";
import ReportDashboard from "./components/ReportDashboard";
import mdu from "./components/mdu";
import ViewReport from "./components/ViewReport";

import { Provider } from "react-redux";

import store from "./store";

import { createBrowserHistory } from "history";

class App extends Component {
  componentDidMount() {}
  constructor() {
    super();
  }

  render() {
    const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );

    return (
      <Provider store={store}>
        <div>
          <Router>
            <Switch>
              <AppRoute exact path="/" layout={Default} component={Home} />

              <AppRoute
                path="/dashboard"
                layout={Dashboard}
                component={ReportDashboard}
              />

              <AppRoute
                  path="/reports"
                  layout={Dashboard}
                  component={UserHome}
              />
              <AppRoute
                  path="/mdu"
                  layout={Dashboard}
                  component={mdu}
              />

              <Route component={NoMatch} />
            </Switch>
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
