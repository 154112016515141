import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    createUser,
    logOutUser,
    setMonthYear,
    loadStatus,
} from "../actions/postAction";
import ReactToPrint from 'react-to-print';

import axios from "axios";
import { BASE_URL, formatDateToString } from "../constant";
import Swal from "sweetalert2";
import { NetworkError } from "./NetworkError";
import { Loading } from "./Loading";

import moment from "moment";
import "moment-timezone";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme, Table } from "@material-ui/core";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { endpoints, http } from "../configs/https";
import { customTheme, YEARS } from "./UserHome";
import "./style.css";
import { Link } from "react-router-dom";
import ViewReport from "./ViewReport";
import { Bar } from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";



const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};

class mdu extends Component {
    constructor(props) {
        super(props);
        var d = new Date();
        var n = d.getFullYear();
        var m = d.getMonth() + 1;
        this.state = {
            data: null,
            month: m !== 1 ? m - 1 : m,
            showFilter: true,
            year: n,
            level: 1,
            networkError: false,
            isLoading: false,
            modal: false,
            modalData: null,
            modalType: "",
            modalOffice: "",
            id: 1,
            viewReport: false,
            name: "",
            dataD: [{ id: 1, name: "Abuja", post_id: 1 }, { id: 2, name: "Oyo", post_id: 2 }, { id: 3, name: "Lagos", post_id: 3 }],
            showModal: false,
            graphData: null,
            xAxis: [],
            yAxis: [],
            startDate: new Date(`${n}/01/01`),
            endDate: new Date(),
        };
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState((prevState) => ({
            showModal: !prevState.showModal,
        }));
    }

    setModalData(office = "tabligh", type = "submitted") {
        this.setState({
            modalData: this.state.data[office][type],
            modalType: type,
            modalOffice: office,
            modal: true,
        });
    }


    showReportStats() {

        console.log("post id")
        const { year, level, startDate, endDate } = this.state;

        let queryString = "";



              if (startDate !== 0) {
            queryString += `date_from=${formatDateToString(startDate)}`;
        }

        if (endDate !== 0) {
            queryString += `&date_to=${formatDateToString(endDate)}`;
        }

        // if (year !== 0) {
        //     queryString += "&year=" + year;
        // }

        if (level !== 0) {
            queryString += "&report_level=" + level;
        }

        // queryString += "&date_from=2022-01-21&date_to=2023-04-21";

        http
            .get(endpoints.MDU_REPORT_STATS + queryString)
            .then((response) => {
                console.log("hello HERE");
                console.log(response.data)
                var xAxis = [];
                var yAxis = [];
                var data = response.data;
                for (var i = 0; i < response.data.length; i++) {
                    console.log(data[i])
                    xAxis.push(data[i].name);
                    yAxis.push(data[i].value);
                }

                console.log(xAxis)

                console.log(yAxis)
                this.setState({
                    isLoading: false,
                    graphData: response.data,
                    xAxis, yAxis

                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    networkError: true,
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage,
                });
            });
    }

    showReport(post_id, name) {
        this.setState({
            name
        })
        console.log("post id")
        const { month, year, level, startDate, endDate } = this.state;

        let queryString = "";


        queryString += "post_id=" + post_id;

        if (startDate !== 0) {
            queryString += `&date_from=${formatDateToString(startDate)}`;
        }

        if (endDate !== 0) {
            queryString += `&date_to=${formatDateToString(endDate)}`;
        }
        // if (year !== 0) {
        //     queryString += "&year=" + year;
        // }

        if (level !== 0) {
            queryString += "&report_level=" + level;
        }

        http
            .get(endpoints.MDU_REPORT + queryString)
            .then((response) => {
                console.log("hello");
                console.log(response.data)
                this.setState({
                    isLoading: false,
                    data: response.data,
                    showModal: true,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    networkError: true,
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage,
                });
            });
    }

    handleChangeEvent(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            graphData: null,
        }, () => {
            this.showReportStats()
        });
        if (name === "level") {
            this.fetchQuery(value)
        }
    }

    fetchQuery(level) {
        this.setState({
            isLoading: true,
            showFilter: false,
            dataD: [],
            networkError: false,
        });

        let queryString = "";

        if (level == 1) {
            queryString = "states";
        }
        else if (level == 2) {
            queryString = "dila";
        }
        else if (level == 3) {
            queryString = "muqami";
        }
        console.log("afeez");
        http
            .get(endpoints.POST_TYPE.replace(":postType", queryString))
            .then((response) => {
                console.log("dele");
                console.log("second", response.data);

                this.setState({
                    isLoading: false,
                    dataD: response.data
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    networkError: true,
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage,
                });
            });
    }

    month_name(dt) {
        var mlist = [
            "",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return mlist[dt];
    }
    options = [
        { label: 2021, value: 2021 },
        { label: 2020, value: 2020 },
        { label: 2019, value: 2019 },
        { label: 2018, value: 2018 },
        { label: 2017, value: 2017 },
        { label: 2016, value: 2016 },
        { label: 2015, value: 2015 },
        { label: 2014, value: 2014 },
        { label: 2013, value: 2013 },
        { label: 2012, value: 2012 },
        { label: 2011, value: 2011 },
        { label: 2010, value: 2010 },
        { label: 2009, value: 2009 },
        { label: 2008, value: 2008 },
        { label: 2007, value: 2007 },
        { label: 2006, value: 2006 },
        { label: 2005, value: 2005 },
        { label: 2004, value: 2004 },
        { label: 2003, value: 2003 },
        { label: 2002, value: 2002 },
        { label: 2001, value: 2001 },
        { label: 2000, value: 2000 },
        { label: 1775, value: 1775 },
    ];

    months = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    componentDidMount() {

        document.title = "MDU - MKAN REPORT";
        this.fetchQuery(1);
        this.showReportStats();
    }
    handleChangeYear(value) {
        const { month } = this.props.monthYear;
        this.setState({ year: value });
        const monthYear = {
            month: month,
            year: value.value,
        };
        this.props.setMonthYear(monthYear);
        this.props.loadStatus(month, value.value);
    }

    handleChangeMonth(value) {
        // this.setState({ month: value });

        // this.checkStatus(value.value, this.state.value.value);
        const { year } = this.props.monthYear;
        this.setState({ month: value });
        const monthYear = {
            month: value.value,
            year: year,
        };
        this.props.setMonthYear(monthYear);
        this.props.loadStatus(value.value, year);
    }
    applyFilter() {
        this.fetchQuery();
    }
    getLevel = function (level) {
        if (level === "1") {
            return "Ilaqa";
        } else if (level === "2") {
            return "Dila";
        } else if (level === "3") {
            return "Muqami";
        }
    };

    getRank(number) {
        const lastDigit = number % 10;
        const secondLastDigit = Math.floor(number / 10) % 10;

        if (secondLastDigit === 1) {
            return "th";
        } else if (lastDigit === 1) {
            return "st";
        } else if (lastDigit === 2) {
            return "nd";
        } else if (lastDigit === 3) {
            return "rd";
        } else {
            return "th";
        }
    }

    render() {
        const {
            month,
            year,
            isLoading,
            level,
            data,
            modalData,
            modalType,
            modalOffice,
            viewReport,
            id,
            name,
            dataD,
            showModal,
            graphData,
            xAxis, yAxis,
            startDate,
            endDate
        } = this.state;

        return (
            <MuiThemeProvider theme={customTheme}>
                <section className="content">

                    {this.state.networkError ? (
                        <NetworkError />
                    ) : (
                        <div>
                            {!viewReport ?
                                <div>
                                    <div className="col-md-12">
                                        <div>
                                            <div className="row">


                                                {/* <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                                                    <FormControl className={"form-control"}>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Year
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={year}
                                                            name={"year"}
                                                            onChange={this.handleChangeEvent}
                                                        >
                                                            {YEARS().reverse().map((option, index) => (
                                                                <MenuItem value={option}>{option}</MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                </div> */}
                                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                                                    <FormControl className={"form-control"}>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Start Date
                                                        </InputLabel>
                                                        <DatePicker
                                                            showYearDropdown
                                                            selected={startDate}
                                                            onChange={(date) => {
                                                                this.setState({ startDate: date}, ()=> {
                                                                    this.showReportStats()
                                                                })
                                                            }}
                                                            selectsStart
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                                                    <FormControl className={"form-control"}>
                                                        <InputLabel id="demo-simple-select-label">
                                                            End Date
                                                        </InputLabel>
                                                        <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => {
                                                        this.setState({ endDate: date}, ()=> {
                                                            this.showReportStats()
                                                        })
                                                    }}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    showYearDropdown
                                                />
                                                    </FormControl>
                                                </div>




                                                <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                                                    <FormControl className={"form-control"}>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Level
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={level}
                                                            name={"level"}
                                                            onChange={this.handleChangeEvent}
                                                        >
                                                            <MenuItem value={3}>Muqami</MenuItem>
                                                            <MenuItem value={2}>Dila</MenuItem>
                                                            <MenuItem value={1}>Ilaqa</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>

                                            {/* <button
                                                onClick={() => this.applyFilter()}
                                                className="btn btn-success mt-5"
                                            >
                                                Apply Filter
                                            </button> */}
                                        </div>
                                    </div>

                                    {/* <center>
              <h2>Your report status for {moment().format("MMMM")} </h2>
            </center> */}
                                    {isLoading ? (
                                        <div className="text-center container">
                                            <center>
                                                <Loading />
                                            </center>
                                        </div>
                                    ) : (
                                        <div>

                                            <div className="row mt-5">
                                                {graphData &&
                                                    <div className={"monthly-sales-chart-wrapper"}
                                                        style={{ position: "relative", margin: "auto", width: "80vw" }}>
                                                        <Bar
                                                            data={{
                                                                labels: xAxis,
                                                                datasets: [{
                                                                    label: 'Top performing ' + this.getLevel(level.toString()),
                                                                    data: yAxis,
                                                                    backgroundColor: '#10b759'
                                                                }]
                                                            }}
                                                            width={100}
                                                            height={50}
                                                            options={{
                                                                responsive: true,
                                                                maintainAspectRatio: false,
                                                                legend: {
                                                                    display: false,
                                                                    labels: {
                                                                        display: false
                                                                    }
                                                                },
                                                                scales: {
                                                                    xAxes: [{
                                                                        display: true,
                                                                        barPercentage: .3,
                                                                        categoryPercentage: .6,
                                                                        gridLines: {
                                                                            display: false
                                                                        },
                                                                        ticks: {
                                                                            fontColor: '#8392a5',
                                                                            fontSize: 10
                                                                        }
                                                                    }],
                                                                    yAxes: [{
                                                                        gridLines: {
                                                                            color: 'rgba(77, 138, 240, .1)'
                                                                        },
                                                                        ticks: {
                                                                            fontColor: '#8392a5',
                                                                            fontSize: 10,

                                                                        }
                                                                    }]
                                                                }
                                                            }}

                                                        />
                                                    </div>
                                                }
                                                {dataD && (
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead className=" text-primary">
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>{this.getLevel(level.toString())}</th>

                                                                    <th>View</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataD.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.name}</td>

                                                                        <Button
                                                                            color="primary"

                                                                            onClick={() => {
                                                                                this.showReport(item.id, item.name)
                                                                            }}>
                                                                            View
                                                                        </Button>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}

                                                <div style={{ textAlign: 'center', width: '100%', marginTop: 30 }}>
                                                    <h2><strong>MDU DATA (TABLE)</strong></h2>
                                                </div>


                                                {graphData && (
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead className=" text-primary">
                                                                <tr>
                                                                    <th>Position</th>
                                                                    <th>{this.getLevel(level.toString())}</th>

                                                                    <th>Score</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {xAxis.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}<sup>{this.getRank(index + 1)}</sup></td>
                                                                        <td>{item}</td>

                                                                        <td>{yAxis[index]}</td>


                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}



                                                <Modal
                                                    isOpen={showModal}
                                                    toggle={this.toggle}
                                                    className={this.props.className}
                                                >
                                                    <ModalHeader toggle={this.toggle}>
                                                        <ReactToPrint
                                                            trigger={() => {
                                                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                                // to the root node of the returned component as it will be overwritten.
                                                                return <a href="#" className={"btn btn-danger"}>Print!</a>;
                                                            }}
                                                            content={() => this.componentRef}
                                                        />
                                                    </ModalHeader>
                                                    <ModalBody>
                                                        {data && (
                                                            <div className="table-responsive">
                                                                {data.data && (
                                                                    <div>
                                                                        <ComponentToPrintMDU data={data} ref={el => (this.componentRef = el)} name={name} level={this.getLevel(level.toString())} year={year} start={formatDateToString(startDate)} end={formatDateToString(endDate)}/>
                                                                    </div>

                                                                )}
                                                            </div>
                                                        )}
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button color="primary" onClick={this.toggle}>
                                                            Close
                                                        </Button>
                                                    </ModalFooter>
                                                </Modal>
                                            </div>

                                        </div>
                                    )}
                                </div>

                                :

                                <ViewReport office={modalOffice} level={level} year={year} month={month} post_id={id} close={() => this.setState({
                                    viewReport: false
                                })} name={name} />


                            }

                        </div>
                    )}
                </section>
            </MuiThemeProvider>
        );
    }
}

export class ComponentToPrintMDU extends React.PureComponent {
    render() {
        const { data, name, level, year, start, end } = this.props;
        return (
            <div>
                <h3>{level} mdu report for {name} in {start} - {end}</h3>
                <table className="table">
                    <thead className=" text-primary">
                        <tr>
                            <th>ID</th>
                            <th>Office</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Atfal</td>
                            <td>{data.data.atfal}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Muhasib</td>
                            <td>{data.data.audit}</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Umoomi</td>
                            <td>{data.data.general}</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>SEH'HAT -E- JISMANI</td>
                            <td>{data.data.health}</td>
                        </tr>


                        <tr>
                            <td>5</td>
                            <td>Maal</td>
                            <td>{data.data.maal}</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Waqar e amal</td>
                            <td>{data.data.waqaramal}</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Nau mubayeen</td>
                            <td>{data.data.new_convert}</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>A'itmad</td>
                            <td>{data.data.secretariat}</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>KHIDMAT E KHALQ</td>
                            <td>{data.data.social}</td>
                        </tr>

                        <tr>
                            <td>10</td>
                            <td>UMOOR E TULABA</td>
                            <td>{data.data.student}</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Tabligh</td>
                            <td>{data.data.tabligh}</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>Tajneed</td>
                            <td>{data.data.tajnid}</td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>Taleem</td>
                            <td>{data.data.taleem}</td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td>Tarbiyya</td>
                            <td>{data.data.tarbiyya}</td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>SANAT-O-TIJARAT</td>
                            <td>{data.data.trade}</td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td>Ishaat</td>
                            <td>{data.data.ishaat}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><strong>Total</strong></td>
                            <td><strong>{data.total}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

mdu.propTypes = {
    logOutUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    user: state.person.user,
    isLoggedIn: state.person.isLoggedIn,
});

export default connect(mapStateToProps, {
    logOutUser,
})(mdu);
