import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { logOutUser } from "../../actions/postAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class UserSidebar extends Component {
  constructor() {
    super();
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {}

  handleLogout() {
    this.props.logOutUser();
    window.location.replace("/");
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color="rose"
        data-background-color="black"
        data-image="/img/sidebar-1.jpg"
      >
        <div className="logo">
          <a href="/" className="simple-text logo-mini">
            <img
              src="logo.jpg"
              alt="MKAN NIGERIA"
              className="brand-image img-circle elevation-3"
            />
          </a>
          <a href="/" className="simple-text logo-normal">
            MKAN REPORT
          </a>
        </div>
        <div className="sidebar-wrapper">
          <div className="user">
            <div className="label label-primary">
              {/* <img src="/img/faces/avatar.jpg" /> */}
            </div>
            <div className="user-info">
              <a href="#" className="username">
                <span>
                  {this.props.isLoggedIn
                    ? "MKAN ID: " + this.props.user.khuddam_id + " "
                    : "Qaid"}
                  {/* {this.props.isLoggedIn ? this.props.user.names : ""} -
            {this.props.user.dila ? " " + this.props.user.dila + " dila" : ""}
                  {this.props.user.state
                    ? " " + this.props.user.state + " Ilaqa"
                    : ""} */}
                </span>
                <span className="sidebar-normal mt-3 myhidden">
                  {this.props.isLoggedIn ? this.props.user.names : ""}
                </span>
                <span className="sidebar-normal mt-3 myhidden">
                  {this.props.user.dila
                    ? " " + this.props.user.dila + " dila"
                    : ""}
                  {this.props.user.state
                    ? " " + this.props.user.state + " Ilaqa"
                    : ""}
                  {this.props.user.muqami
                    ? " " + this.props.user.muqami + " muqami"
                    : ""}
                </span>
              </a>
              <div className="collapse" id="collapseExample">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      <span className="sidebar-mini"> MP </span>
                      <span className="sidebar-normal"> My Profile </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      <span className="sidebar-mini"> EP </span>
                      <span className="sidebar-normal"> Edit Profile </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      <span className="sidebar-mini"> S </span>
                      <span className="sidebar-normal"> Settings </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul className="nav">
            <li className="nav-item active">
              <NavLink
                to="/dashboard"
                className="nav-link "
                activeClassName="active"
              >
                <i className="material-icons">dashboard</i>
                <p> Dashboard </p>
              </NavLink>
            </li>

            <li className="nav-item active">
              <NavLink
                  to="/reports"
                  className="nav-link "
                  activeClassName="active"
              >
                <i className="material-icons">info</i>
                <p> Reports </p>
              </NavLink>
            </li>
            <li className="nav-item active">
              <NavLink
                  to="/mdu"
                  className="nav-link "
                  activeClassName="active"
              >
                <i className="material-icons">info</i>
                <p> MDU </p>
              </NavLink>
            </li>

            <li className="nav-item">
              <a className="nav-link" onClick={this.handleLogout}>
                <i className=" fa fa-sign-out text-danger" />

                <p> Log out </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      //     <aside className="main-sidebar sidebar-dark-primary elevation-4">

      //     <NavLink  to="/" className="brand-link">

      //         <img src="/logo.jpeg" alt="MKAN NIGERIA" className="brand-image img-circle elevation-3" style={{opacity: .8}}/>
      //         <span className="brand-text font-weight-light">MKAN REPORT</span>
      //     </NavLink>

      //     <div className="sidebar">

      //         <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      //             <div className="image">
      //                 <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image"/>
      //             </div>
      //             <div className="info">
      //                 <a href="#" className="d-block">
      //                 {this.props.isLoggedIn
      //             ? this.props.user.name + " ("+ this.props.user.khuddam_id+")":"Qaid"}
      //                 </a>
      //             </div>
      //         </div>

      //         <nav className="mt-2 ">
      //             <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      //             <li className="nav-item">
      //                     <NavLink to="/dashboard" className="nav-link" activeClassName="active">
      //                         <i className="nav-icon fa fa-user"></i>
      //                         <p className="text">Dashboard</p>
      //                     </NavLink>
      //                 </li>
      //                 <li className="nav-item has-treeview menu-open">
      //                     <a href="#" className="nav-link ">
      //                         <i className="nav-icon fa fa-dashboard"></i>
      //                         <p>
      //                             Select Office
      //                             <i className="right fa fa-angle-left"></i>
      //                         </p>
      //                     </a>
      //                     <ul className="nav nav-treeview">
      //                         <li className="nav-item">
      //                             <NavLink to="/CENSUS" className="nav-link " activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>TAJNEED</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/tarbiyyah" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>TARBIYYAH</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/tabligh" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>TABLIGH</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/Secretariat" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>ETEMA’AD</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/Tarbiyyat" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>TARBIYYAT</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/UMOOR_E_TULABA" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>UMOOR-E-TULABA</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/WAQAR_E_AMAL" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>WAQAR-E-AMAL</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/ATFAL" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-male nav-icon"></i>
      //                                 <p>ATFAL</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/MAAL" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>MAAL</p>
      //                             </NavLink>

      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/TAHRIK_JADID" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>TAHRIK JADID</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/MUHASBA" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>MUHASBA</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/Sehhat_e_jismani" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>SEHHRAT E JISMANI</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/Sanat_tijarat" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>SANAT TIJARAT</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/ISHAAT" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>ISHAAT</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/KHIDMATE_E_KHALQ" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>KHIDMATE E KHALQ</p>
      //                             </NavLink>
      //                         </li>
      //                         <li className="nav-item">
      //                             <NavLink to="/UMOOMI" className="nav-link" activeClassName="active">
      //                                 <i className="fa fa-circle-o nav-icon"></i>
      //                                 <p>UMOOMI</p>
      //                             </NavLink>
      //                         </li>
      //                     </ul>
      //                 </li>

      //                 {/* <li className="nav-item">
      //                     <p className="nav-link text-white" onClick={this.handleLogout}>
      //                         <i className="nav-icon fa fa-sign-out text-danger"></i>
      //                         <p className="text">Log out</p>
      //                     </p>
      //                 </li> */}
      //             </ul>
      //         </nav>

      //     </div>

      // </aside>
    );
  }
}

UserSidebar.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  user: state.person.user,
  isLoggedIn: state.person.isLoggedIn
});

export default connect(mapStateToProps, { logOutUser })(UserSidebar);
