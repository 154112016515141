import React from "react";

import { Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { createUser, logOutUser } from "../actions/postAction";
import PropTypes from "prop-types";
import { Loading } from "./Loading";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import Script from "react-load-script";
import { SITE_URL } from "../constant";
import { endpoints, http } from "../configs/https";
import "../newstyle.css";
import "../util.css";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      khuddam_id: "",
      token: "",
      password: "",
      isLoading: false,
      form_head: "MKAN REPORT BACK OFFICE",
      show: true,
      hasError: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      http
        .post(endpoints.VERIFY_AUTH, { token: this.props.user.token })
        .then((response) => {
          // this.handleLoginSuccess(response);
          console.log(response);
        })
        .catch((error) => {
          let errorMessage =
            (typeof error === "string" && error) ||
            "An error occured, please try again in a moment.";
          // alert(JSON.stringify(error));
          console.log("error");
        });

      // axios
      //   .post(SITE_URL + "auth/verify/", { token: this.props.user.token })
      //   .then(res => {
      //     console.log(res);

      //     console.log(res.data);
      //     if (res.status == 200) {
      //       this.setState({});
      //     } else {
      //       this.props.logOutUser();
      //     }
      //   })
      //   .catch(error => {
      //     this.props.logOutUser();
      //   });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }
  handleLoginSuccess(res) {
    // const user = {
    //   khuddam_id: this.state.khuddam_id,
    //   token: res.data.token
    // };
    const user = res.data;
    user.khuddam_id = this.state.khuddam_id;
    this.props.createUser(user);
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      icon: "success",
      title: "Signed in successfully",
    });
    this.setState({
      isLoading: false,
      khuddam_id: "",
      password: "",
      name: "",
      hasError: false,
      form_head: "MKAN REPORT BACK OFFICE",
    });
    window.location.replace("/dashboard");
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
      hasError: false,
      form_head: "Logging In",
    });

    http
      .post(endpoints.LOGIN, {
        mkanid: this.state.khuddam_id,
        password: this.state.password,
      })
      .then((response) => {
        this.handleLoginSuccess(response);
      })
      .catch((error) => {
        console.log(error);
        let errorMessage =
          (typeof error === "string" && error) ||
          "An error occured, please try again in a moment.";
        // alert(JSON.stringify(error));
        toast.error(errorMessage);

        this.setState({
          isLoading: false,
          hasError: true,

          form_head: errorMessage,
        });
      });

    // axios
    //   .post(SITE_URL + "login/", {
    //     mkanid: this.state.khuddam_id,
    //     password: this.state.password
    //   })
    //   .then(res => {
    //     console.log(res);
    //     console.log(res.data);
    //     if (res.status !== 200) {
    //       this.setState({
    //         isLoading: false,
    //
    //         form_head: "Invalid Log In Details",
    //         hasError: true
    //       });
    //       const Toast = Swal.mixin({
    //         toast: true,
    //         position: "top-end",
    //         showConfirmButton: false,
    //         timer: 3000
    //       });
    //
    //       Toast.fire({
    //         type: "error",
    //         title: "Invalid MKAN ID/Password "
    //       });
    //     } else {
    //       // const user = {
    //       //   khuddam_id: this.state.khuddam_id,
    //       //   token: res.data.token
    //       // };
    //       const user = res.data;
    //       user.khuddam_id = this.state.khuddam_id;
    //       this.props.createUser(user);
    //       const Toast = Swal.mixin({
    //         toast: true,
    //         position: "top-end",
    //         showConfirmButton: false,
    //         timer: 3000
    //       });
    //
    //       Toast.fire({
    //         type: "success",
    //         title: "Signed in successfully"
    //       });
    //       this.setState({
    //         isLoading: false,
    //         khuddam_id: "",
    //         password: "",
    //         name: "",
    //         hasError: false,
    //         form_head: "MKAN RePORT PORTAL"
    //       });
    //       window.location.replace("/DASHBOARD");
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error.message);
    //     if (error.message === "Network Error") {
    //       this.setState({
    //         isLoading: false,
    //         hasError: true,
    //
    //         form_head: "An error occured"
    //       });
    //       const Toast = Swal.mixin({
    //         toast: true,
    //         position: "top-end",
    //         showConfirmButton: false,
    //         timer: 3000
    //       });
    //
    //       Toast.fire({
    //         type: "warning",
    //         title: "Network error, try again. "
    //       });
    //     } else {
    //       if (error.response.status === 401) {
    //         this.setState({
    //           isLoading: false,
    //
    //           form_head: "Invalid Log In Details",
    //           hasError: true
    //         });
    //         const Toast = Swal.mixin({
    //           toast: true,
    //           position: "top-end",
    //           showConfirmButton: false,
    //           timer: 3000
    //         });
    //
    //         Toast.fire({
    //           type: "error",
    //           title: "Invalid MKAN ID/Password "
    //         });
    //       } else if (error.response.status === 400) {
    //         this.setState({
    //           isLoading: false,
    //
    //           form_head: "Invalid Log In Details",
    //           hasError: true
    //         });
    //         const Toast = Swal.mixin({
    //           toast: true,
    //           position: "top-end",
    //           showConfirmButton: false,
    //           timer: 3000
    //         });
    //
    //         Toast.fire({
    //           type: "error",
    //           title: "Invalid MKAN ID/Password "
    //         });
    //       } else {
    //         this.setState({
    //           isLoading: false,
    //
    //           form_head: "Something went wrong, try again.",
    //           hasError: true
    //         });
    //         const Toast = Swal.mixin({
    //           toast: true,
    //           position: "top-end",
    //           showConfirmButton: false,
    //           timer: 3000
    //         });
    //
    //         Toast.fire({
    //           type: "error",
    //           title: "Something went wrong, try again."
    //         });
    //       }
    //     }
    //   });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div>
        <ToastContainer />
        {!this.props.isLoggedIn ? (
          <form className="login100-form mcard" onSubmit={this.handleSubmit}>
            <div className="login100-form-avatar">
              <img src="/logo.jpeg" alt="AVATAR" />
            </div>

            <span
              className={
                "login100-form-title p-t-20 p-b-45 " +
                (this.state.hasError ? "text-danger" : "text-home")
              }
            >
              {this.state.form_head}
            </span>

            <div
              className="wrap-input100 validate-input m-b-10"
              data-validate="Username is required"
            >
              <input
                className="input100"
                required
                type="text"
                placeholder="Khuddam Id"
                name="khuddam_id"
                onChange={this.handleChange}
                value={this.state.khuddam_id}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-user"></i>
              </span>
            </div>

            <div
              className="wrap-input100 m-b-10"
              data-validate="Password is required"
            >
              <input
                className="input100"
                type="password"
                placeholder="Password"
                name="password"
                required
                onChange={this.handleChange}
                value={this.state.password}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <i className="fa fa-lock"></i>
              </span>
            </div>

            <div className="container-login100-form-btn p-t-10">
              <button
                className="login100-form-btn"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}

                {isLoading && <span>Logging in</span>}
                {!isLoading && <span> Login</span>}
              </button>
            </div>

            <div className="text-center w-full p-t-25 p-b-20">
              <a href="https://khuddam.ng/" className="txt1">
                &copy; {new Date().getFullYear()} - MKA-Nigeria.
              </a>
            </div>

            {/* <div className="text-center w-full">
          <a className="txt1" href="#">
            Create new account
            <i className="fa fa-long-arrow-right"></i>
          </a>
        </div> */}
          </form>
        ) : (
          <a href="/dashboard" className="btn btn-primary btn-pill">
            Loading dashboard...
          </a>
        )}
      </div>
    );
  }
}

Home.propTypes = {
  createUser: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.person.user,
  isLoggedIn: state.person.isLoggedIn,
});

export default connect(mapStateToProps, { createUser, logOutUser })(Home);

// import React from "react";

// import { Nav, Navbar } from "react-bootstrap";
// import { connect } from "react-redux";
// import { createUser, logOutUser } from "../actions/postAction";
// import PropTypes from "prop-types";
// import { Loading } from "./Loading";
// import { NavLink } from "react-router-dom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import Script from "react-load-script";
// import { SITE_URL } from "../constant";

// class Home extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       khuddam_id: "",
//       token: "",
//       password: "",
//       isLoading: false,
//       form_head: "Qaideen Log in",
//       show: true,
//       hasError: false
//     };
//     this.handleChange = this.handleChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   componentDidMount() {
//     if (this.props.isLoggedIn) {
//       axios
//         .post(SITE_URL + "auth/verify/", { token: this.props.user.token })
//         .then(res => {
//           console.log(res);

//           console.log(res.data);
//           if (res.status == 200) {
//             this.setState({});
//           } else {
//             this.props.logOutUser();
//           }
//         })
//         .catch(error => {
//           this.props.logOutUser();
//         });
//     }
//   }

//   handleChange(event) {
//     const { name, value } = event.target;
//     this.setState({
//       [name]: value
//     });
//   }
//   handleSubmit(event) {
//     event.preventDefault();
//     this.setState({
//       isLoading: true,
//       hasError: false,
//       form_head: "Logging In"
//     });

//     axios
//       .post(SITE_URL + "login/", {
//         mkanid: this.state.khuddam_id,
//         password: this.state.password
//       })
//       .then(res => {
//         console.log(res);
//         console.log(res.data);
//         if (res.status !== 200) {
//           this.setState({
//             isLoading: false,

//             form_head: "Invalid Log In Details",
//             hasError: true
//           });
//           const Toast = Swal.mixin({
//             toast: true,
//             position: "top-end",
//             showConfirmButton: false,
//             timer: 3000
//           });

//           Toast.fire({
//             type: "error",
//             title: "Invalid MKAN ID/Password "
//           });
//         } else {
//           // const user = {
//           //   khuddam_id: this.state.khuddam_id,
//           //   token: res.data.token
//           // };
//           const user = res.data;
//           user.khuddam_id = this.state.khuddam_id;
//           this.props.createUser(user);
//           const Toast = Swal.mixin({
//             toast: true,
//             position: "top-end",
//             showConfirmButton: false,
//             timer: 3000
//           });

//           Toast.fire({
//             type: "success",
//             title: "Signed in successfully"
//           });
//           this.setState({
//             isLoading: false,
//             khuddam_id: "",
//             password: "",
//             name: "",
//             hasError: false,
//             form_head: "Qaideen Log in"
//           });
//           window.location.replace("/DASHBOARD");
//         }
//       })
//       .catch(error => {
//         console.log(error.message);
//         if (error.message === "Network Error") {
//           this.setState({
//             isLoading: false,
//             hasError: true,

//             form_head: "An error occured, check your internet connection."
//           });
//           const Toast = Swal.mixin({
//             toast: true,
//             position: "top-end",
//             showConfirmButton: false,
//             timer: 3000
//           });

//           Toast.fire({
//             type: "warning",
//             title: "Network error, try again. "
//           });
//         } else {
//           if (error.response.status === 401) {
//             this.setState({
//               isLoading: false,

//               form_head: "Invalid Log In Details",
//               hasError: true
//             });
//             const Toast = Swal.mixin({
//               toast: true,
//               position: "top-end",
//               showConfirmButton: false,
//               timer: 3000
//             });

//             Toast.fire({
//               type: "error",
//               title: "Invalid MKAN ID/Password "
//             });
//           }
//           else if (error.response.status === 400) {
//             this.setState({
//               isLoading: false,

//               form_head: "Invalid Log In Details",
//               hasError: true
//             });
//             const Toast = Swal.mixin({
//               toast: true,
//               position: "top-end",
//               showConfirmButton: false,
//               timer: 3000
//             });

//             Toast.fire({
//               type: "error",
//               title: "Invalid MKAN ID/Password "
//             });
//           }else {
//             this.setState({
//               isLoading: false,

//               form_head: "Something went wrong, try again.",
//               hasError: true
//             });
//             const Toast = Swal.mixin({
//               toast: true,
//               position: "top-end",
//               showConfirmButton: false,
//               timer: 3000
//             });

//             Toast.fire({
//               type: "error",
//               title: "Something went wrong, try again."
//             });
//           }
//         }
//       });
//   }

//   render() {
//     return (
//       <div className="intro-section" id="home-section">
//         <div className="slide-1" data-stellar-background-ratio="0.5">
//           <div className="container">
//             <div className="row align-items-center">
//               <div className="col-12">
//                 <div className="row align-items-center">
//                   <div className="col-lg-6 mb-4">
//                     <h1 data-aos="fade-up" data-aos-delay="100">
//                       MKAN Report Portal{" "}
//                     </h1>
//                     <p className="mb-4" data-aos="fade-up" data-aos-delay="200">
//                       This platform is dedicated for administrative use of
//                       office bearers across Majlis level for prompt reporting of
//                       activities and quick analysis for decision making.
//                     </p>
//                     <p data-aos="fade-up" data-aos-delay="300">
//                       <a
//                         href="#"
//                         className="btn btn-primary py-3 px-5 btn-pill"
//                       >
//                         Learn How To Use
//                       </a>
//                     </p>
//                   </div>

//                   <div
//                     className="col-lg-5 ml-auto"
//                     data-aos="fade-up"
//                     data-aos-delay="500"
//                   >
//                     <form className="form-box" onSubmit={this.handleSubmit}>
//                       <h3
//                         className={
//                           "h4  mb-4 " +
//                           (this.state.hasError ? "text-danger" : "text-black")
//                         }
//                       >
//                         {this.state.form_head}
//                       </h3>
//                       {this.state.isLoading ? <Loading /> : ""}
//                       {!this.props.isLoggedIn ? (
//                         <div>
//                           <div className="form-group">
//                             <input
//                               type="text"
//                               className="form-control"
//                               placeholder="Khuddam Id"
//                               name="khuddam_id"
//                               onChange={this.handleChange}
//                               value={this.state.khuddam_id}
//                               required
//                             />
//                           </div>
//                           {/* <div className="form-group">
//                   <input type="text" className="form-control" placeholder="Name "  name ="name"  onChange ={this.handleChange} value={this.state.name} required/>
//                 </div> */}
//                           <div className="form-group">
//                             <input
//                               type="password"
//                               className="form-control"
//                               placeholder="Password"
//                               name="password"
//                               onChange={this.handleChange}
//                               value={this.state.password}
//                               required
//                             />
//                           </div>

//                           <div className="form-group">
//                             <input
//                               type="submit"
//                               className="btn btn-primary btn-pill"
//                               value="Login"
//                             />
//                           </div>
//                         </div>
//                       ) : (
//                         <a
//                           href="/dashboard"
//                           className="btn btn-primary btn-pill"
//                         >
//                           Go to DASHBOARD
//                         </a>
//                       )}
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// Home.propTypes = {
//   createUser: PropTypes.func.isRequired,
//   logOutUser: PropTypes.func.isRequired,
//   user: PropTypes.object.isRequired,
//   isLoggedIn: PropTypes.bool.isRequired
// };
// const mapStateToProps = state => ({
//   user: state.person.user,
//   isLoggedIn: state.person.isLoggedIn
// });

// export default connect(
//   mapStateToProps,
//   { createUser, logOutUser }
// )(Home);
