import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class Taleem extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table" >
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>
                        Total number of Taleem classes conducted in
                        the month
                    </td>
                    <td>{data.t_number_classes}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who participated in Talimul
                        Quran session
                    </td>
                    <td>{data.session}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who participated in Age Group
                        Book Review
                    </td>
                    <td>{data.book_review}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who participated in ICT training
                    </td>
                    <td>{data.ict}</td>
                </tr>
                <tr>
                    <td>
                        No of Khuddam who participated in proficiency
                        exam preparation
                    </td>
                    <td>{data.exam_prep}</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>{data.total}</td>
                </tr>
                <tr>
                    <td>No of books reviewed during this month</td>
                    <td>{data.reviewed}</td>
                </tr>
                <tr>
                    <td>Nazim Taleem's Phone Number</td>
                    <td>{data.phone}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td> {getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default Taleem;
