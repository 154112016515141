import React from 'react';
import Loader from 'react-loader-spinner'

export const Loading=()=> (
    <div  className="spinner">

    <Loader
       type="ThreeDots"
       color="#00BFFF"
       height="40"	
       width="40"
    /> 
    </div>
)
