import axios from "axios";
import Swal from "sweetalert2";
import store from "../store";

/**
 * here we have the base url for all http request
 * that will be made to the REST API
 */
const DEV_url = "https://209.105.243.210:8800/api/v1/";
const LIVE_url = "https://reports.khuddam.ng/service";
export const url = LIVE_url;

/**
 * here we have all the possible enpoints that can be
 * used to access data on the REST API
 */
export const endpoints = {
  LOGIN: "/login/", // POST
  VERIFY_AUTH: "/auth/verify/",
  QUERY_REPORT: "backOffice/query/?office=:office",
  MONTHLY_REPORT: "backOffice/monthly/?",
  MDU_REPORT: "backOffice/mdu/?",
  POST_TYPE: "backOffice/post-type/?postType=:postType",
  MDU_REPORT_STATS: "backOffice/mdu/stats/?",
};

/**
 * the in key is used to validate every request made to the REST API
 * this ensures that the request is secure and permitted.
 */
export const apiKey = "";

/**
 * default headers for http request
 */
export const headers = {
  // "Content-Type": "application/json",
  // "Access-Control-Allow-Origin": "*"
};

/**
 * parse error response
 */
function parseError(error) {
  console.log("err");
  console.log(error.response);
  if (error.response.status == 401) {
    store.dispatch(logout());
    window.location.replace("/");
  }

  if (!error) {
    return Promise.reject("An unknown error occured.");
  }

  //console.log(error.response.data.error);

  if (typeof error === "string") {
    return Promise.reject(error);
  }

  if (error && error.response) {
    if (error.response.data && error.response.data.error) {
      return Promise.reject(error.response.data.error);
    }

    if (error.response.data && error.response.data.message) {
      return Promise.reject(error.response.data.message);
    }

    return Promise.reject("An unknown error occured.");
  }

  return Promise.reject(error.message || "An unknown error occured.");
}

/**
 * parse response
 */
function logout() {
  return {
    type: "LOGOUT_USER",
  };
}

function parseBody(response) {
  const { error } = response;
  console.log(store.getState());
  if (!error) {
    return response;
  } else {
    console.log(new Error(error.message));
    // if (error.message == "Network Error") {
    //   Promise.reject(error.message);
    // }

    return parseError(new Error(error));
  }
}

let instance = axios.create({
  baseURL: url,
});

/**
 * add a response interceptor for axios instace
 */
instance.interceptors.response.use(
  (response) => {
    console.log(response);
    if (response.status == 401 || response.status == 403) {
      store.dispatch(logout());
      window.location.replace("/");
    }
    return parseBody(response);
  },
  (error) => {
    if (error.message === "Network Error") {
      return Promise.reject(error.message);
    }

    return parseError(error);
  }
);

// request header
instance.interceptors.request.use(
  async (config) => {
    try {
      // get stored user
      // let user = await asyncGet(STORE_KEYS.AUTH_USER);
      let serializedState = localStorage.getItem("state");
      //if (serializedState === null) return undefined;
      // let user = JSON.parse(serializedState);
      // user = user.user;

      const state = store.getState();
      let authToken = null;
      console.log("listen");
      console.log(state);
      if (state.person && state.person.user)
        authToken = state.person.user.token;

      // add default headers
      config.headers = Object.assign({}, config.headers, headers);
      // check if a user was found
      if (state.person && state.person.user) {
        // add user login token to request header
        config.headers = Object.assign({}, config.headers, {
          Authorization: `Bearer ${authToken}`,
        });
      }
      // return transformed config
      return config;
    } catch (error) {
      // reject error if failed
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * export instance as http
 */
export const http = instance;
