import React, {Component} from 'react';
import moment from "moment";
import {getLevel} from "../../constant";

class NewConvert extends Component {
    render() {
        const {data} = this.props;
        return (

            <table className="table">
                <thead className=" text-primary">
                <tr>
                    <th>Field</th>
                    <th>Response</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>Submitted By</td>
                    <td>{data.submitted_by} {data.user && <span>({data.user.details.surname} {data.user.details.names} - {data.user.details.post})</span>}</td>
                </tr>
                <tr>
                    <td>
                        Number of Nau-Mubaiyeen Khuddam
                        participating in Tarbiyyati class this
                        month
                    </td>
                    <td>
                        {data.naumubayin}
                    </td>
                </tr>
                <tr>
                    <td>Attendance</td>
                    <td>
                        {data.attendance}
                    </td>
                </tr>
                <tr>
                    <td>
                        Number of Nau-Mubaiyeen Majlis
                        Tarbiyyati Camp was organised this month
                    </td>
                    <td>
                        {data.tarbiyyati}
                    </td>
                </tr>
                <tr>
                    <td>
                        No. of Nau-Mubaiyeen Khuddam who
                        listened to Live Friday Sermon of Huzoor
                        this month
                    </td>
                    <td>{data.sermon}</td>
                </tr>
                <tr>
                    <td>
                        No. of Nau-Mubaiyeen Khuddam who wrote
                        letters to Huzoor for prayer this month
                    </td>
                    <td>{data.huzur}</td>
                </tr>
                <tr>
                    <td>
                        No. of Nau-Mubaiyeen who observe 5 daily
                        prayers
                    </td>
                    <td>{data.salat}</td>
                </tr>
                <tr>
                    <td>
                        No. of Nau-Mubaiyeen who recite the Holy
                        Quran
                    </td>
                    <td>{data.quran}</td>
                </tr>
                <tr>
                    <td>
                        No. of Nau-Mubaiyeen Khuddam who have
                        become Daiee illallah this month
                    </td>
                    <td>{data.daiee}</td>
                </tr>
                <tr>
                    <td>Month, Year</td>
                    <td>
                        {moment()
                            .month(data.month - 1)
                            .format("MMMM")}
                        , {data.year}
                    </td>
                </tr>
                <tr>
                    <td>Level</td>
                    <td>{getLevel(data.report_level)}</td>
                </tr>
                <tr>
                    <td>Date of Submission</td>
                    <td>
                        {moment(data.date).format(
                            "MMMM Do YYYY, h:mm a"
                        )}
                    </td>
                </tr>
                </tbody>
            </table>

        );
    }
}

export default NewConvert;
